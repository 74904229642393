import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  change,
  reduxForm,
  clearSubmitErrors,
  submit,
  stopSubmit
} from 'redux-form';
import { withLocalize, Translate } from 'react-localize-redux';
import FacebookLogin from 'react-facebook-login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BasePureComponent } from 'common/components/Base';
import Text from 'common/components/Form/components/Text';
import Button from 'common/components/Form/components/Button';
import { addAlert } from 'common/components/Alerts/actions';
import { processErrors } from 'common/components/Form';
import { scrollToTop } from 'common/util';
import { checkValidity } from 'common/components/Form/utility';
import * as errors from 'common/util/errors';
import i18n from './i18n.json';

/* Login form. */
class LoginForm extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }

    // for resetting a password
    this.state = {
      ...this.state,
      forgotPassword: false
    };
  }

  login(values) {
    // are we requesting a password reset?
    if (this.state.forgotPassword) {
      // request password reset
      return this.props
        .onPasswordReset(values)
        .then(() => {
          // get back to the login form
          this.setState({ forgotPassword: false });
        })
        .catch(e => {
          // process the error(s)
          processErrors(
            e,
            this.props.translate,
            this.props.dispatch,
            'loginForm.error.generic'
          );
        });
    } else {
      // login
      return this.props
        .onSubmit(values)
        .catch(e => {
          // process the error(s)
          processErrors(
            e,
            this.props.translate,
            this.props.dispatch,
            'loginForm.error.generic',
            (error, dispatch) => {
              // handle errors specific to this form
              switch (error.code) {
                // not authenticated
                case errors.NOT_AUTHENTICATED:
                  return {
                    [values.provider
                      ? 'oAuth2Error'
                      : '_error']: this.props.translate(
                      `loginForm.error.${
                        values.provider ? 'oAuth2' : 'generic'
                      }`
                    )
                  };
                default:
                  break;
              }

              return null;
            }
          );
        })
        .finally(() => {
          // clear OAuth2 values
          this.props.dispatch(change(this.props.form, 'provider', null));
          this.props.dispatch(change(this.props.form, 'token', null));

          // get back to the top
          scrollToTop();
        });
    }
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <>
        <Form
          id={this.props.form}
          onSubmit={this.props.handleSubmit(values => this.login(values))}
          onChange={() => {
            // check HTML5 validity; this is necessary for user typing, and we do
            // it on a slight delay to account for dynamic fields that may appear
            checkValidity(this);
          }}
          onBlur={() => {
            // check HTML5 validity; this is necessary for browser auto-fills
            checkValidity(this);
          }}
        >
          <div className="card">
            <div className="card-header">
              <Translate
                id={
                  'loginForm.' +
                  (this.state.forgotPassword ? 'forgotPassword' : 'login')
                }
              />
            </div>
            <div className="card-body">
              {/* errors */}
              {this.props.error && (
                <div className="has-error mb-3">
                  <div className="fsp-form-error">{this.props.error}</div>
                </div>
              )}
              <div className="form-row form-group">
                {/* userName */}
                <Text
                  colspan={6}
                  colbreak="md"
                  type={
                    this.props.emailIsUserName
                      ? this.props.allowPhoneAsUserName
                        ? 'emailOrPhone'
                        : 'email'
                      : null
                  }
                  label={this.props.translate(
                    `loginForm.field.${
                      this.props.emailIsUserName ? 'email' : 'userName'
                    }${this.props.allowPhoneAsUserName ? 'OrPhone' : ''}.label`
                  )}
                  name="userName"
                  autoComplete="username"
                  placeholder={this.props.translate(
                    `loginForm.field.${
                      this.props.emailIsUserName ? 'email' : 'userName'
                    }${this.props.allowPhoneAsUserName ? 'OrPhone' : ''}.label`
                  )}
                  tooltip={this.props.translate(
                    `loginForm.field.${
                      this.props.emailIsUserName ? 'email' : 'userName'
                    }${this.props.allowPhoneAsUserName ? 'OrPhone' : ''}.title`
                  )}
                  normalize={value => {
                    if (
                      value &&
                      this.props.emailIsUserName &&
                      this.props.allowPhoneAsUserName
                    ) {
                      // if we have an '@', do nothing
                      if (!value.includes('@')) {
                        // strip everything other than digits
                        const stripped = value.replace(/\D/g, '');

                        // if we have 10, it's a phone number
                        if (stripped.length === 10) {
                          return stripped;
                        }
                      }
                    } else if (
                      value &&
                      !this.props.emailIsUserName &&
                      this.props.allowPhoneAsUserName
                    ) {
                      // this is trickier; we assume that 10 digits mixed
                      // with standard phone number decorators is in fact
                      // a phone number and not a username
                      if (!value.includes('@')) {
                        // strip phone number decorators
                        const stripped = value
                          .replace(/\(/g, '')
                          .replace(/\)/g, '')
                          .replace(/\./g, '')
                          .replace(/-/g, '');

                        // if we have only 10 digits left, we assume
                        // it's a phone number
                        if (
                          stripped.length === 10 &&
                          stripped === stripped.replace(/\D/g, '')
                        ) {
                          return stripped;
                        }
                      }
                    }

                    return value;
                  }}
                  minLength={this.props.emailIsUserName ? 5 : 3}
                  maxLength={128}
                  pattern={
                    // note that we use a super-generic email pattern here on purpose
                    this.props.emailIsUserName &&
                    this.props.allowPhoneAsUserName
                      ? '([^@\\s]+@[^@\\s]+\\.[^@\\s]+)|(\\d{10})'
                      : null
                  }
                  required
                  disabled={this.props.submitting}
                  autoTrimEnd
                />

                {/* password */}
                {!this.state.forgotPassword && (
                  <Text
                    colspan={6}
                    colbreak="md"
                    type="password"
                    label={this.props.translate(
                      'loginForm.field.password.label'
                    )}
                    name="password"
                    autoComplete="current-password"
                    placeholder={this.props.translate(
                      'loginForm.field.password.label'
                    )}
                    tooltip={this.props.translate(
                      'loginForm.field.password.title'
                    )}
                    minLength={8}
                    maxLength={32}
                    required
                    disabled={this.props.submitting}
                  />
                )}
              </div>

              {!this.state.forgotPassword && (
                <div className="form-row form-group">
                  {/* forgot password */}
                  <div className="col text-center">
                    <span
                      className="fsp-link"
                      onClick={() => {
                        // clear any errors
                        this.props.dispatch(clearSubmitErrors(this.props.form));
                        this.props.dispatch(stopSubmit(this.props.form));

                        // switch to the password reset form
                        this.setState({ forgotPassword: true });
                      }}
                    >
                      <Translate id="loginForm.forgotPassword" />
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="card-footer d-print-none">
              <div className="form-row">
                {/* cancel */}
                {this.state.forgotPassword && (
                  <div className="col text-left">
                    <Button
                      title={this.props.translate(
                        'loginForm.button.cancel.title'
                      )}
                      onClick={() => this.setState({ forgotPassword: false })}
                      alternate={<FontAwesomeIcon icon="times" />}
                    >
                      <Translate id="loginForm.button.cancel.label" />
                    </Button>
                  </div>
                )}

                <div className="col text-right">
                  {/* forgot password */}
                  {this.state.forgotPassword && (
                    <Button
                      submit
                      disabled={
                        !this.state.htmlValid ||
                        (this.props.invalid && !this.props.submitFailed) ||
                        (!this.props.invalid &&
                          !this.props.submitFailed &&
                          this.props.pristine) ||
                        this.props.submitting
                      }
                      title={this.props.translate(
                        'loginForm.button.forgotPassword.title'
                      )}
                      alternate={<FontAwesomeIcon icon="check" />}
                    >
                      <Translate id="loginForm.button.forgotPassword.label" />
                    </Button>
                  )}

                  {/* login */}
                  {!this.state.forgotPassword && (
                    <Button
                      submit
                      disabled={
                        !this.state.htmlValid ||
                        (this.props.invalid && !this.props.submitFailed) ||
                        (!this.props.invalid &&
                          !this.props.submitFailed &&
                          this.props.pristine) ||
                        this.props.submitting
                      }
                      title={this.props.translate(
                        'loginForm.button.login.title'
                      )}
                      alternate={<FontAwesomeIcon icon="check" />}
                    >
                      <Translate id="loginForm.button.login.label" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>

        {/* Facebook */}
        {this.props.allowFacebook && !this.state.forgotPassword && (
          <div className="container-fluid">
            <div className="row">
              {/* separator */}
              <div className="col text-center mb-2">
                <hr width="40%" />
              </div>
            </div>

            {/* errors */}
            {this.props.oAuth2Error && (
              <div className="row">
                <div className="col fsp-form-error mt-0 mb-3">
                  {this.props.oAuth2Error}
                </div>
              </div>
            )}

            <div className="row">
              <div className="col text-center">
                <span
                  title={this.props.translate(
                    'loginForm.button.loginWithFacebook.title'
                  )}
                >
                  <FacebookLogin
                    version="3.1"
                    autoLoad={false}
                    appId={process.env.REACT_APP_FB_APP_ID}
                    redirectUri={`${window.location.origin}/`}
                    responseType="token"
                    state="fbLogin"
                    scope="public_profile"
                    fields="name"
                    size="medium"
                    language={this.props.language === 'es' ? 'es_LA' : 'en_US'}
                    textButton={this.props.translate(
                      'loginForm.button.loginWithFacebook.label'
                    )}
                    callback={response => {
                      // process the response
                      if (response && response.accessToken) {
                        // we have a token, now pass that to the server to log the guardian in
                        this.props.doOAuth2Login(
                          'facebook',
                          response.accessToken
                        );
                      } else if (
                        response &&
                        (typeof response.status === 'undefined' ||
                          response.status === 'unknown')
                      ) {
                        // the user closed the window
                      } else {
                        console.error('Error signing into Facebook', response);
                        this.props.oAuth2LoginError();
                      }
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

// decorate with reduxForm()
LoginForm = reduxForm({
  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
      dispatch(stopSubmit(props.form));
    }
  }
})(LoginForm);

// map state to properties relevant to this component
const mapStateToProps = state => ({
  // current language
  language: window.$localStorage.getItem('language'),

  // oAuth2 error?
  oAuth2Error:
    state.form && state.form.loginForm && state.form.loginForm.submitErrors
      ? state.form.loginForm.submitErrors.oAuth2Error
      : null
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // initiate OAuth2 login by submitting the form
  doOAuth2Login: (provider, token) => {
    // set the values on the form
    dispatch(change(ownProps.form, 'provider', provider));
    dispatch(change(ownProps.form, 'token', token));

    // submit the form
    dispatch(submit(ownProps.form));
  },

  // error signing in via OAuth2
  oAuth2LoginError: () => {
    dispatch(
      addAlert('error', ownProps.translate('loginForm.error.oAuth2'), 5000)
    );
  }
});

// turn this into a container component
LoginForm = withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm)
);

// set prop types and required-ness
LoginForm.propTypes = {
  form: PropTypes.string.isRequired,
  emailIsUserName: PropTypes.bool,
  allowPhoneAsUserName: PropTypes.bool,
  allowFacebook: PropTypes.bool,
  translations: PropTypes.object
};

// set default props
LoginForm.defaultProps = {
  form: 'loginForm',
  emailIsUserName: true,
  allowPhoneAsUserName: false,
  allowFacebook: false
};

export default LoginForm;
