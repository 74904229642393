import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import { BasePureComponent } from 'common/components/Base';
import Select from 'common/components/Form/components/Select';
import { normalizeNumber } from 'common/util/formatters';
import i18n from './i18n.json';
import './styles.scss';

/* Site affiliation information. */
class SiteAffiliations extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <div>
        <div className="form-row form-group">
          {/* volunteerOrganization */}
          <Select
            colspan={6}
            label={this.props.translate(
              'volunteerOrganizationForm.field.volunteerOrganizationId.label'
            )}
            name="volunteerOrganizationId"
            tooltip={this.props.translate(
              'volunteerOrganizationForm.field.volunteerOrganizationId.title'
            )}
            normalize={value => normalizeNumber(value)}
            disabled={this.props.readOnly || this.props.submitting}
            options={(() => {
              let items = [];
              items.push(<option key="" value="" />);
              if (this.props.volunteerOrganizations) {
                for (
                  let i = 0;
                  i < this.props.volunteerOrganizations.length;
                  i++
                ) {
                  items.push(
                    <option
                      key={this.props.volunteerOrganizations[i].key}
                      value={this.props.volunteerOrganizations[i].key}
                    >
                      {this.props.volunteerOrganizations[i].value}
                    </option>
                  );
                }
              }
              return items;
            })()}
            translateOptions={false}
          />
        </div>
      </div>
    );
  }
}

// turn this into a container component
SiteAffiliations = withLocalize(connect()(SiteAffiliations));

// set prop types and required-ness
SiteAffiliations.propTypes = {
  form: PropTypes.string.isRequired,
  admin: PropTypes.bool,
  volunteerOrganizations: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
  submitting: PropTypes.bool,
  isRestricted: PropTypes.bool,
  translations: PropTypes.object
};

// set default props
SiteAffiliations.defaultProps = {
  admin: false,
  readOnly: false,
  submitting: false,
  isRestricted: false
};

export default SiteAffiliations;
