import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Translate, withLocalize } from 'react-localize-redux';

import { BasePureComponent } from 'common/components/Base';
import Text from 'common/components/Form/components/Text';
import Toggle from 'common/components/Form/components/Toggle';
import Generic from 'common/components/Form/components/Generic';
import { formatPhone, normalizeNumber } from 'common/util/formatters';
import i18n from './i18n.json';

/* Contact information. */
class Contact extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <div>
        <div className="form-row form-group">
          {/* email */}
          <Text
            colspan={6}
            type="email"
            label={this.props.translate('contactForm.field.email.label')}
            name="email"
            placeholder={this.props.translate('contactForm.field.email.label')}
            tooltip={this.props.translate('contactForm.field.email.title')}
            maxLength={128}
            required
            disabled={this.props.readOnly || this.props.submitting}
          />

          {/* phone */}
          <Text
            colspan={4}
            type="phone"
            label={this.props.translate('contactForm.field.phone.label')}
            name="phone"
            placeholder={this.props.translate('contactForm.field.phone.label')}
            tooltip={this.props.translate('contactForm.field.phone.title')}
            format={formatPhone}
            normalize={value => normalizeNumber(value)}
            minLength={12}
            maxLength={12}
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            required
            disabled={this.props.readOnly || this.props.submitting}
          />
        </div>

        <div className="form-row form-group">
          {/* can contact? */}
          <Generic label={this.props.translate('contactForm.contact.lead')}>
            {/* can text? */}
            <Toggle
              unwrapped
              name="canText"
              label={this.props.translate('contactForm.field.canText.label')}
              tooltip={this.props.translate('contactForm.field.canText.title')}
              disabled={
                this.props.readOnly ||
                this.props.submitting ||
                !this.props.phone ||
                this.props.phone.length === 0 ||
                (this.props.admin && !this.props.canText)
              }
              containerChildren={
                !this.props.admin &&
                (!this.props.readOnly || this.props.canText) ? (
                  <sup>
                    <Translate id="contactForm.messageRates" />
                  </sup>
                ) : null
              }
            />

            {/* can email? */}
            <Toggle
              unwrapped
              name="canEmail"
              label={this.props.translate('contactForm.field.canEmail.label')}
              tooltip={this.props.translate('contactForm.field.canEmail.title')}
              disabled={
                this.props.readOnly ||
                this.props.submitting ||
                !this.props.email ||
                this.props.email.length === 0
              }
            />
          </Generic>
        </div>

        <div className="form-row form-group">
          {/* receiveNewsletter */}
          <Toggle
            name="receiveNewsletter"
            label={this.props.translate(
              'contactForm.field.receiveNewsletter.label'
            )}
            tooltip={this.props.translate(
              'contactForm.field.receiveNewsletter.title'
            )}
            disabled={
              this.props.readOnly ||
              this.props.submitting ||
              !this.props.email ||
              this.props.email.length === 0
            }
          />
        </div>
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // cell phone
  phone: formValueSelector(ownProps.form)(state, 'phone'),

  // email
  email: formValueSelector(ownProps.form)(state, 'email'),

  // can text
  canText: formValueSelector(ownProps.form)(state, 'canText')
});

// turn this into a container component
Contact = withLocalize(connect(mapStateToProps)(Contact));

// set prop types and required-ness
Contact.propTypes = {
  form: PropTypes.string.isRequired,
  admin: PropTypes.bool,
  readOnly: PropTypes.bool,
  submitting: PropTypes.bool,
  isRestricted: PropTypes.bool,
  translations: PropTypes.object
};

// set default props
Contact.defaultProps = {
  admin: false,
  readOnly: false,
  submitting: false,
  isRestricted: false
};

export default Contact;
