import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import requiredIf from 'react-required-if';

import { BaseFormComponent } from '..';
import { renderField } from '..';
import { clearFieldError } from '../../utility';
import * as v from 'app/variables';

/* A form select field, along with some of the styling around it. */
class Select extends BaseFormComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div
        className={
          this.props.unwrapped
            ? 'has-error'
            : (this.props.colspan && this.props.colspan > 0
                ? `col-${this.props.colbreak ? `${this.props.colbreak}-` : ''}${
                    this.props.colspan
                  }`
                : 'col') + ' has-error'
        }
      >
        <Field
          type="select"
          size={this.props.size}
          label={this.props.label}
          name={this.props.name}
          autoComplete={this.props.autoComplete}
          labelClassName={`col-form-label col-form-label-${this.props.size}`}
          className={`form-control form-control-${this.props.size}`}
          component={this.props.component}
          placeholder={this.props.placeholder}
          tooltip={this.props.tooltip}
          options={this.props.options}
          keyPrefix={this.props.keyPrefix}
          emptyOption={this.props.emptyOption}
          translateOptions={this.props.translateOptions}
          required={this.props.required}
          disabled={this.props.disabled}
          format={this.props.format}
          normalize={this.props.normalize}
          parse={this.props.parse}
          validate={this.props.validate}
          onFocus={this.props.onFocus}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onDragStart={this.props.onDragStart}
          onDrop={this.props.onDrop}
          translate={this.props.translate}
        />
        {this.props.containerChildren}
      </div>
    );
  }
}

// renderer
const renderSelect = ({
  meta: { form, touched, error },
  size,
  input,
  id = null,
  label,
  autoComplete = null,
  labelClassName = '',
  className = '',
  placeholder,
  tooltip = null,
  options,
  keyPrefix,
  emptyOption,
  translateOptions,
  required = false,
  disabled = false,
  translate
}) => {
  // options renderer
  const renderOptions = (emptyOption, options, keyPrefix, translate) => {
    const items = [];
    if (emptyOption) {
      items.push(<option key="" value="" />);
    }
    for (let key in options) {
      items.push(
        <option key={`${key}`} value={`${key}`}>
          {translate(keyPrefix + '.' + key)}
        </option>
      );
    }
    return items;
  };

  return renderField(
    size,
    input.name,
    label,
    disabled,
    required,
    touched,
    error,
    labelClassName,
    tooltip,
    <select
      {...input}
      id={id ? id : input.name}
      disabled={disabled}
      required={required}
      placeholder={!disabled ? placeholder : null}
      className={className}
      data-html="true"
      title={tooltip}
      autoComplete={autoComplete}
      onFocus={input.onFocus}
      onChange={e => {
        // clear HTML5 errors; this is the only place we
        // can clear field-specific server errors
        clearFieldError(form, input.name);

        // if we have a callback, invoke it
        if (input.onChange) {
          input.onChange(e);
        }
      }}
      onBlur={input.onBlur}
      onDragStart={input.onDragStart}
      onDrop={input.onDrop}
    >
      {/* translate the options */}
      {translateOptions &&
        renderOptions(emptyOption, options, keyPrefix, translate)}

      {/* use the options as provided */}
      {!translateOptions && options}
    </select>
  );
};

// set prop types and required-ness
Select.propTypes = {
  unwrapped: PropTypes.bool,
  colbreak: PropTypes.string,
  colspan: PropTypes.number,
  size: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  component: PropTypes.func,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  format: PropTypes.func,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  emptyOption: PropTypes.bool,
  translateOptions: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  keyPrefix: requiredIf(PropTypes.string, props => props.translateOptions),
  translate: requiredIf(PropTypes.func, props => props.translateOptions),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  containerChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};

// set default props
Select.defaultProps = {
  unwrapped: false,
  colbreak: 'sm',
  size: v.formWidgetSize,
  component: renderSelect,
  emptyOption: true,
  translateOptions: true,
  required: false,
  disabled: false
};

export default Select;
