import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import moment from 'moment/moment';

import { BaseComponent } from 'common/components/Base';
import { addAlert, clearAlert } from 'common/components/Alerts/actions';
import { logout } from 'common/entities/Security/actions';
import { getSessionId } from 'common/util/session';
import {
  refreshSession,
  getSessionTimeRemaining
} from 'common/entities/Security/actions';
import { navigate } from 'common/components/Navigate/actions';
import i18n from './i18n.json';

/*
 * Monitors session time remaining. Note that we intentionally
 * use an impure component so that it renders every time.
 */
class SessionChecker extends BaseComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // time remaining checker
    this.sessionTimer = null;

    // alerts
    this.sessionAlert = null;
    this.expiredAlert = null;

    // heartbeat tracker
    this.lastHeartbeat = 0;
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // start a timer to check session time remaining;
    // we need to fire it at an interval less than the
    // amount of time that we'll show the "session is
    // about to expire" warning
    this.sessionTimer = setInterval(() => this.checkSessionTime(), 30000);
  }

  checkSessionTime() {
    // we don't want a failure here to impact anything
    try {
      // if logged in, check session time remaining
      const loggedIn = getSessionId() ? true : false;
      if (loggedIn) {
        // check the session time remaining
        this.props
          .dispatch(getSessionTimeRemaining())
          .then(remaining => {
            // if 3 minutes or less, show a warning message
            if (remaining && remaining > 0 && remaining <= 3 * 60) {
              this.props.dispatch(
                (this.sessionAlert = addAlert(
                  'warning',
                  this.props.translate('session.warning.timeoutNear'),
                  60000
                ))
              );
            } else if (!remaining || remaining <= 0) {
              // if we have a session alert displaying, clear it
              if (this.sessionAlert) {
                this.props.dispatch(clearAlert(this.sessionAlert.id));
              }

              // if we have a warning alert displaying, clear it
              if (this.sessionAlert) {
                this.props.dispatch(clearAlert(this.sessionAlert.id));
              }

              // show a message
              this.props.dispatch(
                (this.expiredAlert = addAlert(
                  'error',
                  this.props.translate('session.error.expired'),
                  0
                ))
              );

              // logout locally
              this.props.dispatch(logout(true));

              // go back to the login page, optionally with redirect
              this.props.dispatch(
                navigate('login', {
                  redirect: window.location
                    ? window.location.pathname +
                      (window.location.search ? window.location.search : '')
                    : null
                })
              );
            }
          })
          .catch(e => {
            console.error('Error getting session time remaining', e);
          });
      }
    } catch (e) {
      console.error('Error checking session time', e);
    }
  }

  componentWillUnmount() {
    // parent
    super.componentWillUnmount();

    // clear any session timer
    try {
      if (this.sessionTimer) {
        clearInterval(this.sessionTimer);
        this.sessionTimer = null;
      }
    } catch (e) {
      console.error('Error clearing session timer', e);
    }

    // clear any alert
    if (this.sessionAlert) {
      this.props.dispatch(clearAlert(this.sessionAlert));
    }
  }

  render() {
    // parent
    super.render();

    // runs when there is activity
    const onActivity = () => {
      // if we have a session alert displaying, clear it
      if (this.sessionAlert) {
        this.props.dispatch(clearAlert(this.sessionAlert.id));
      }

      // if we have an expiration alert displaying, clear it
      if (this.expiredAlert) {
        this.props.dispatch(clearAlert(this.expiredAlert.id));
      }

      // if we have a session, and if we haven't sent a
      // heartbeat within the past 30 seconds, do so
      if (getSessionId()) {
        const current = moment().unix();
        if (current > this.lastHeartbeat + 30) {
          try {
            this.props.dispatch(refreshSession());
          } catch (e) {
            console.error('Error sending heartbeat', e);
          }
          this.lastHeartbeat = current;
        }
      }
    };

    // render our children
    return (
      <div
        onKeyPress={() => {
          onActivity();
        }}
        onClick={() => {
          onActivity();
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

// turn this into a container component
SessionChecker = withLocalize(connect()(SessionChecker));

// set prop types and required-ness
SessionChecker.propTypes = {
  children: PropTypes.element.isRequired
};

// set default props
SessionChecker.defaultProps = {};

// make router props accessible; this is necessary to
// drive re-renders based on path changes
export default withRouter(SessionChecker);
