/**
 * Authenticates a volunteer.
 */
export function authenticate(userName, password) {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'AUTHENTICATE_VOLUNTEER',
      payload: {
        request: {
          method: 'post',
          url: '/volunteers/authenticate',
          data: { userName: userName, password: password }
        }
      }
    });
  };
}

/**
 * Requests a password reset.
 */
export function forgotPassword(userName) {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'FORGOT_VOLUNTEER_PASSWORD',
      payload: {
        request: {
          method: 'put',
          url: `/volunteers/${userName}/forgotPassword`
        }
      }
    });
  };
}

/**
 * Sets a password using a token.
 */
export function setPassword(userName, token, password) {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'SET_VOLUNTEER_PASSWORD',
      payload: {
        request: {
          method: 'post',
          url: `/volunteers/${userName}/setCredentials`,
          data: {
            token: token,
            newCredentials: { userName: userName, password: password }
          }
        }
      }
    });
  };
}
