import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import BasePage from 'common/components/Page';
import { BreadcrumbsItem } from 'common/components/Breadcrumbs';
import Overview from './widgets/Overview';
import LinkList from './widgets/LinkList';
import i18n from './i18n.json';
import './styles.scss';

/* Dashboard page. */
class Dashboard extends BasePage {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <div>
        <BreadcrumbsItem home={true} />

        <div className="container-fluid p-0 fsp-widgets">
          <div className="row no-gutters">
            {/* overview */}
            <div className="col">
              <Overview {...this.props} />
            </div>
          </div>
        </div>

        <div className="card-columns">
          {/* I want to... */}
          <LinkList {...this.props} />
        </div>
      </div>
    );
  }
}

// turn this into a container component
Dashboard = withLocalize(connect()(Dashboard));

// set prop types and required-ness
Dashboard.propTypes = { volunteer: PropTypes.object.isRequired };

export default Dashboard;
