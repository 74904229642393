import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { BasePureComponent } from 'common/components/Base';
import { Breadcrumbs } from 'common/components/Breadcrumbs';
import Home from 'app/scenes/Home';
import SetPassword from 'app/scenes/SetPassword';
import Info from 'common/scenes/Info';
import Volunteer from 'app/scenes/Volunteer';
import ChildProtectionPolicy from 'app/scenes/ChildProtectionPolicy';
import { getSessionId } from 'common/util/session';

/*
 * The application's main content. This content changes as the user navigates.
 * Note that routes need to be ordered from most specific to least specific.
 */
class Main extends BasePureComponent {
  render() {
    // parent
    super.render();

    // for securing some routes behind login
    const loggedIn = getSessionId() && this.props.volunteer ? true : false;
    const secureRoute = component => {
      return loggedIn ? (
        component
      ) : (
        <Redirect
          to={{
            pathname:
              '/' +
              (this.props.location &&
              this.props.location.pathname &&
              this.props.location.pathname.length > 0
                ? '~' + encodeURIComponent(this.props.location.pathname)
                : '')
          }}
        />
      );
    };

    return (
      <div className="fsp-main">
        <div className="fsp-page">
          {loggedIn && <Breadcrumbs current={this.props.location.pathname} />}
          <Switch>
            {/* set password */}
            <Route exact path="/password/:token" component={SetPassword} />

            {/* application info */}
            <Route
              exact
              path="/info"
              render={props => secureRoute(<Info {...this.props} {...props} />)}
            />

            {/* profile management */}
            <Route
              exact
              path="/account"
              render={props => (
                // note that this route is not secure on purpose: new account creation
                <Volunteer {...this.props} {...props} />
              )}
            />

            {/* child protection policy */}
            <Route
              exact
              path="/cpp"
              render={props =>
                secureRoute(
                  <ChildProtectionPolicy {...this.props} {...props} />
                )
              }
            />

            {/* redirect */}
            <Route
              exact
              path="/~:redirect"
              render={props => <Home {...this.props} {...props} />}
            />

            {/* default */}
            <Route render={props => <Home {...this.props} {...props} />} />
          </Switch>
        </div>
      </div>
    );
  }
}

// make router props accessible; this is necessary to
// drive re-renders based on path changes
Main = withRouter(connect()(Main));

// set prop types and required-ness
Main.propTypes = { volunteer: PropTypes.object };

export default Main;
