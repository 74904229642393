import React from 'react';
import { connect } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';

import { BasePureComponent } from 'common/components/Base';
import i18n from './i18n.json';

/* Wizard instructions. */
class Instructions extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <div className="form-row">
        <div className="col fsp-instructions">
          <p>
            <Translate id="instructionsForm.summary" />
          </p>
          <p>
            <Translate id="instructionsForm.getStarted" />
          </p>
        </div>
      </div>
    );
  }
}

// turn this into a container component
export default withLocalize(connect()(Instructions));
