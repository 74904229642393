import React from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import BasePage from 'common/components/Page';
import SetPasswordForm from 'common/forms/SetPasswordForm';
import { navigate } from 'common/components/Navigate/actions';
import { addAlert } from 'common/components/Alerts/actions';
import { showBusy, hideBusy } from 'common/components/Busy/actions';
import i18n from './i18n.json';
import { logout } from 'common/entities/Security/actions';
import { setPassword } from 'app/entities/Volunteer/actions';
import { extractURLParams } from 'common/util/index';

/* Set a new password. */
class SetPassword extends BasePage {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // this page is invalid without a token
    if (!extractURLParams(this.props.match).token) {
      // leave
      this.props.home();
    }

    // log the user out if they hit this page
    this.props.logout();
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <SetPasswordForm
        onCancel={() => this.props.home()}
        onSubmit={values =>
          this.props.setPassword(
            extractURLParams(this.props.match).token,
            values
          )
        }
      />
    );
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // go home
  home: () => {
    dispatch(navigate('home'));
  },

  // log the user out
  logout: () => {
    // the logout functionality cleans things up
    return dispatch(logout());
  },

  // set password
  setPassword: (token, { userName, password }) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // do the reset
    return dispatch(setPassword(userName, token, password))
      .then(() => {
        // fire message
        dispatch(
          addAlert(
            'success',
            ownProps.translate('setPassword.passwordSet'),
            3000
          )
        );

        // back to login
        dispatch(navigate('home'));
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  }
});

// turn this into a container component
export default withLocalize(
  connect(
    null,
    mapDispatchToProps
  )(SetPassword)
);
