import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { withLocalize, Translate } from 'react-localize-redux';
import $ from 'jquery';

import BasePage from 'common/components/Page';
import MessageBox from 'common/components/MessageBox';
import * as errors from 'common/util/errors';
import { addAlert } from 'common/components/Alerts/actions';
import { storeOnContext } from 'common/entities/Context/actions';
import { registerSession } from 'common/util/session';
import { showBusy, hideBusy } from 'common/components/Busy/actions';
import { logout } from 'common/entities/Security/actions';
import { forgotPassword } from 'app/entities/Volunteer/actions';
import { authenticate as login } from 'app/entities/Volunteer/actions';
import LoginForm from 'common/forms/LoginForm';
import * as v from 'app/variables';
import i18n from './i18n.json';

/* Front door. */
class FrontDoor extends BasePage {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // make sure we start clean
    this.props.clean();

    // fringe condition: we can sometimes
    // get here with a modal still displayed
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // reset the document title to the default
    document.title = v.defaultTitle;
  }

  render() {
    // parent
    super.render();

    return (
      <div className="container-fluid">
        <div>
          <MessageBox>
            <Translate
              id="frontDoor.noAccount.text"
              data={{
                link: (
                  <Link
                    to="/account"
                    title={this.props.translate('frontDoor.noAccount.label')}
                    className="fsp-inline-link"
                  >
                    <Translate id="frontDoor.noAccount.link" />
                  </Link>
                )
              }}
            />
          </MessageBox>
        </div>
        <div className="fsp-form fsp-login">
          <LoginForm
            onSubmit={this.props.login}
            onPasswordReset={this.props.forgotPassword}
          />
        </div>
      </div>
    );
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // make sure we clean out any cruft from previous sessions
  clean: () => {
    // the logout functionality cleans things up
    return dispatch(logout(true));
  },

  // reset password
  forgotPassword: values => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // de-structure the values
    const { userName } = values;

    // request the reset
    return dispatch(forgotPassword(userName))
      .then(() => {
        // success message
        dispatch(
          addAlert(
            'success',
            ownProps.translate('frontDoor.passwordReset'),
            4000
          )
        );

        // destroy the form
        dispatch(destroy('loginForm'));
      })
      .catch(e => {
        // we allow network errors through
        if (
          e &&
          e.code &&
          (e.code === errors.NETWORK_ERROR ||
            e.code === errors.FORBIDDEN ||
            e.code === errors.NOT_FOUND)
        ) {
          throw e;
        } else {
          // this call should never return a business error, but just in case it does,
          // we need to eat it (and not even log it) to prevent user ID fishing; therfore,
          // we treat this as a success scenario from the user perspective

          // success message
          dispatch(
            addAlert(
              'success',
              ownProps.translate('frontDoor.passwordReset'),
              4000
            )
          );

          // destroy the form
          dispatch(destroy('loginForm'));
        }
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // login
  login: values => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // de-structure the values
    const { userName, password } = values;

    // do the login
    return dispatch(login(userName, password))
      .then(authenticated => {
        // get the user
        const user = authenticated.volunteer;

        // if we didn't get one, that's bad
        if (!user) {
          // fire message
          dispatch(
            addAlert('error', ownProps.translate('frontDoor.error.login'), 4000)
          );
        } else {
          // register the session
          registerSession(authenticated.token);

          // store the user on the context
          dispatch(storeOnContext('volunteer', user));
        }
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // reset
  resetForm: () => {
    // destroy the form
    dispatch(destroy('loginForm'));
  }
});

// turn this presentation component into a container component
export default withLocalize(connect(null, mapDispatchToProps)(FrontDoor));
