/**
 * Updates credentials.
 */
export function updateVolunteerCredentials(
  volunteerId,
  currentUserName,
  newUserName,
  currentPassword,
  newPassword
) {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'UPDATE_VOLUNTEER_CREDENTIALS',
      payload: {
        request: {
          method: 'put',
          url: `/volunteers/${volunteerId}/credentials`,
          data: {
            oldCredentials: {
              userName: currentUserName,
              password: currentPassword
            },
            newCredentials: { userName: newUserName, password: newPassword }
          }
        }
      }
    });
  };
}

/**
 * Fetches a volunteer by ID.
 */
export function fetchVolunteerById(volunteerId) {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'FETCH_VOLUNTEER',
      payload: {
        request: {
          method: 'get',
          url: `/volunteers/${volunteerId}`
        }
      }
    }).catch(e => {
      // there is one error we handle
      if (e && e.code && e.code === 700) {
        // no volunteer with the given ID
        return null;
      } else {
        throw e;
      }
    });
  };
}

/**
 * Adds a new volunteer.
 */
export function addVolunteer(volunteer) {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'ADD_VOLUNTEER',
      payload: {
        request: {
          method: 'post',
          url: `/volunteers`,
          data: volunteer
        }
      }
    });
  };
}

/**
 * Updates an existing volunteer.
 */
export function updateVolunteer(volunteer) {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'UPDATE_VOLUNTEER',
      payload: {
        request: {
          method: 'put',
          url: `/volunteers/${volunteer.id}`,
          data: volunteer
        }
      }
    });
  };
}

/**
 * Fetches valid volunteer interests.
 */
export function fetchVolunteerInterests() {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'FETCH_VOLUNTEER_INTERESTS',
      payload: {
        request: {
          method: 'get',
          url: `/volunteers/interests`
        }
      }
    });
  };
}

/**
 * Fetches all volunteer organizations.
 */
export function fetchVolunteerOrganizationsForSelect() {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'FETCH_VOLUNTEER_ORGANIZATIONS_FOR_SELECT',
      payload: {
        request: {
          method: 'get',
          url: `/volunteerOrganizations/selectOptions`
        }
      }
    });
  };
}
