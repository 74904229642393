import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import SignaturePad from 'react-signature-pad-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment/moment';

import BasePage from 'common/components/Page';
import PageHeader from 'common/components/PageHeader';
import { BreadcrumbsItem } from 'common/components/Breadcrumbs';
import Content from 'common/components/Content';
import MessageBox from 'common/components/MessageBox';
import Button from 'common/components/Form/components/Button';
import { showBusy, hideBusy } from 'common/components/Busy/actions';
import { addAlert } from 'common/components/Alerts/actions';
import { navigate } from 'common/components/Navigate/actions';
import { clone, currentDate } from 'common/util/index';
import pen from './images/pen.png';
import i18n from './i18n.json';
import './styles.scss';

/* Child protection policy acceptance. */
class ChildProtectionPolicy extends BasePage {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // the signature pads
    this.signaturePad = null;
    this.guardianSignaturePad = null;

    // the signature images
    this.state = {
      ...this.state,
      signature: null,
      guardianSignature: null
    };

    // for text management
    this.state = {
      ...this.state,
      textLoaded: null
    };
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // if this user has already signed the CPP, leave
    if (this.props.entity.childProtectionPolicy) {
      this.props.home();
    }
  }

  render() {
    // parent
    super.render();

    // minor?
    const minor =
      this.props.entity.dateOfBirth &&
      currentDate().diff(moment(this.props.entity.dateOfBirth), 'years') < 18;

    // render
    return (
      <div>
        <div>
          <BreadcrumbsItem
            to={this.props.location.pathname}
            text={this.props.translate('childProtectionPolicy.header')}
          />
          <PageHeader
            text={this.props.translate('childProtectionPolicy.header')}
          />
        </div>

        {/* the policy */}
        <Content
          name="web.childProtectionPolicy"
          errorText={this.props.translate('childProtectionPolicy.errorLoading')}
          onSuccess={() => {
            this.setState({ textLoaded: true });
          }}
          onError={() => {
            this.setState({ textLoaded: false });
          }}
        />

        {/* signature pad: do not show if we failed to load the text */}
        {this.state.textLoaded && (
          <div>
            {/* signature */}
            <>
              {/* minor? */}
              {minor && !this.props.requireGuardianSignatureForMinor && (
                <div className="row">
                  <div className="col">
                    <MessageBox flavor="warning" className="mt-2">
                      <Translate id="childProtectionPolicy.underAge" />
                    </MessageBox>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col">
                  <h4>
                    <Translate id="childProtectionPolicy.field.childProtectionPolicySignature.label" />
                  </h4>
                </div>
              </div>
              <div className="row">
                {/* show the signature pad */}
                <div className="col">
                  <FontAwesomeIcon
                    icon="times"
                    className="fsp-agree-signature-clear"
                    title={this.props.translate(
                      'childProtectionPolicy.clear.title'
                    )}
                    onClick={() => {
                      // clear the pad
                      this.signaturePad.clear();

                      // clear the signature
                      this.setState({ signature: null });
                    }}
                  />
                  <div
                    title={this.props.translate(
                      'childProtectionPolicy.field.childProtectionPolicySignature.title'
                    )}
                    style={{
                      cursor: `url(${pen}) 0 32, auto`
                    }}
                  >
                    <SignaturePad
                      ref={signaturePad => {
                        // capture a reference
                        this.signaturePad = signaturePad;
                      }}
                      redrawOnResize
                      height={200}
                      options={{
                        backgroundColor: 'rgb(240,240,240)',
                        onEnd: data => {
                          // if we have a signature...
                          if (data && data.target && data.target.toDataURL) {
                            // capture the signature
                            this.setState({
                              signature: data.target.toDataURL('image/png')
                            });
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* name and date */}
              <div className="row fsp-agree-details">
                <div className="d-block d-sm-none col-sm-6 text-right">
                  {!minor && this.props.entity.firstName && (
                    <span>{this.props.entity.firstName}</span>
                  )}{' '}
                  {!minor && this.props.entity.lastName && (
                    <span>{this.props.entity.lastName}</span>
                  )}
                </div>
                <div className="d-none d-sm-inline-block col-sm-6">
                  {!minor && this.props.entity.firstName && (
                    <span>{this.props.entity.firstName}</span>
                  )}{' '}
                  {!minor && this.props.entity.lastName && (
                    <span>{this.props.entity.lastName}</span>
                  )}
                </div>
                <div className="col-sm-6 text-right">
                  {currentDate().format('LL')}
                </div>
              </div>
            </>

            {/* guardian signature */}
            {minor && this.props.requireGuardianSignatureForMinor && (
              <>
                <div className="row">
                  <div className="col">
                    <MessageBox flavor="warning" className="mt-4">
                      <Translate id="childProtectionPolicy.underAgeSignature" />
                    </MessageBox>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <h4>
                      <Translate id="childProtectionPolicy.field.guardianChildProtectionPolicySignature.label" />
                    </h4>
                  </div>
                </div>
                <div className="row">
                  {/* show the signature pad */}
                  <div className="col">
                    <FontAwesomeIcon
                      icon="times"
                      className="fsp-agree-signature-clear"
                      title={this.props.translate(
                        'childProtectionPolicy.clear.title'
                      )}
                      onClick={() => {
                        // clear the pad
                        this.guardianSignaturePad.clear();

                        // clear the signature
                        this.setState({ guardianSignature: null });
                      }}
                    />
                    <div
                      title={this.props.translate(
                        'childProtectionPolicy.field.guardianChildProtectionPolicySignature.title'
                      )}
                      style={{
                        cursor: `url(${pen}) 0 32, auto`
                      }}
                    >
                      <SignaturePad
                        ref={signaturePad => {
                          // capture a reference
                          this.guardianSignaturePad = signaturePad;
                        }}
                        redrawOnResize
                        height={200}
                        options={{
                          backgroundColor: 'rgb(240,240,240)',
                          onEnd: data => {
                            // if we have a signature...
                            if (data && data.target && data.target.toDataURL) {
                              // capture the signature
                              this.setState({
                                guardianSignature: data.target.toDataURL(
                                  'image/png'
                                )
                              });
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* agree */}
            <div className="row mt-3">
              <div className="col text-right">
                <Button
                  disabled={
                    !this.state.signature ||
                    (minor &&
                      this.props.requireGuardianSignatureForMinor &&
                      !this.state.guardianSignature)
                  }
                  onClick={() => {
                    // save them
                    this.props.save(
                      this.state.signature,
                      this.state.guardianSignature,
                      this.props.entity
                    );
                  }}
                  title={this.props.translate(
                    'childProtectionPolicy.button.sign.title'
                  )}
                  alternate={<FontAwesomeIcon icon="check" />}
                >
                  <Translate id="childProtectionPolicy.button.sign.label" />
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // go home
  home: () => {
    dispatch(navigate('home'));
  },

  // saves the signature
  save: (signature, guardianSignature, entity) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // we need to muck with the object, so clone it
    entity = clone(entity);

    // stamp CPP boolean, signature, and date
    entity.childProtectionPolicy = true;
    entity.childProtectionPolicySignature = signature;
    entity.signedChildProtectionPolicyDate = currentDate().format('YYYY-MM-DD');
    if (ownProps.requireGuardianSignatureForMinor && guardianSignature) {
      entity.guardianChildProtectionPolicy = true;
      entity.guardianChildProtectionPolicySignature = guardianSignature;
      entity.guardianSignedChildProtectionPolicyDate = currentDate().format(
        'YYYY-MM-DD'
      );
    }

    // if we have a partial address, remove it;
    // it can prevent the save from going through
    if (entity.address && !entity.address.street1) {
      delete entity.address;
    }

    // do the save
    return dispatch(ownProps.updateEntity(entity))
      .then(entity => {
        // show a success message
        dispatch(
          addAlert(
            'success',
            ownProps.translate('childProtectionPolicy.signed'),
            4000
          )
        );

        // if we have a callback, call it
        if (ownProps.onUpdate) {
          ownProps.onUpdate(entity);
        }

        // go to the dashboard
        dispatch(navigate('home'));

        // propagate the result
        return entity;
      })
      .catch(e => {
        console.error('Error signing CPP', e);
        dispatch(
          addAlert(
            'error',
            ownProps.translate('childProtectionPolicy.error.sign'),
            6000
          )
        );
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  }
});

// turn this into a container component
ChildProtectionPolicy = withLocalize(
  connect(null, mapDispatchToProps)(ChildProtectionPolicy)
);

// set prop types and required-ness
ChildProtectionPolicy.propTypes = {
  entity: PropTypes.object.isRequired,
  updateEntity: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  requireGuardianSignatureForMinor: PropTypes.bool
};

// set default props
ChildProtectionPolicy.defaultProps = {
  requireGuardianSignatureForMinor: false
};

export default ChildProtectionPolicy;
