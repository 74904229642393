import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BasePage from 'common/components/Page';
import i18n from './i18n.json';
import './styles.scss';

/* Widget container. */
class WidgetContainer extends BasePage {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // for hiding
    this.state = {
      ...this.state,
      showBody: true
    };
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <div className="card fsp-widget">
        {(this.props.name ||
          this.props.showSizeControls ||
          this.props.onRefresh) && (
          <div className="card-title d-flex flex-row flex-grow fsp-widget-header">
            {(this.props.showSizeControls || this.props.onRefresh) && (
              <div className="fsp-widget-controls text-left">
                {this.props.showSizeControls && (
                  <>
                    {this.state.showBody ? (
                      <span
                        className="fsp-widget-control"
                        onClick={() => {
                          this.setState({ showBody: false });
                        }}
                        title={this.props.translate(
                          'widgetContainer.control.minimize.title'
                        )}
                      >
                        <FontAwesomeIcon icon="window-minimize" />
                      </span>
                    ) : (
                      <span
                        className="fsp-widget-control"
                        onClick={() => {
                          this.setState({ showBody: true });
                        }}
                        title={this.props.translate(
                          'widgetContainer.control.maximize.title'
                        )}
                      >
                        <FontAwesomeIcon icon="window-maximize" />
                      </span>
                    )}
                  </>
                )}

                {this.props.onRefresh && (
                  <span
                    className="fsp-widget-control"
                    onClick={() => {
                      this.props.onRefresh();
                    }}
                    title={this.props.translate(
                      'widgetContainer.control.refresh.title'
                    )}
                  >
                    <FontAwesomeIcon icon="sync" />
                  </span>
                )}
              </div>
            )}
            <div className="flex-grow" />
            {this.props.name && (
              <div className="text-right">
                <h4>{this.props.name}</h4>
              </div>
            )}
          </div>
        )}
        {this.state.showBody && (
          <div className="card-body">{this.props.children}</div>
        )}
      </div>
    );
  }
}

// turn this into a container component
WidgetContainer = withLocalize(connect()(WidgetContainer));

// set prop types and required-ness
WidgetContainer.propTypes = {
  name: PropTypes.string,
  showSizeControls: PropTypes.bool,
  onRefresh: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired
};

// set default props
WidgetContainer.defaultProps = { showSizeControls: false };

export default WidgetContainer;
