import React from 'react';
import PropTypes from 'prop-types';

import { BasePureComponent } from 'common/components/Base';

/* A group of elements with non-breaking spaces in between. */
class ElementGroup extends BasePureComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    if (this.props.children && Array.isArray(this.props.children)) {
      const spaces = this.props.spaces;
      const items = [];
      let lastWasSpace = true;
      for (let i = 0; i < this.props.children.length; i++) {
        const child = this.props.children[i];
        if (child) {
          if (!lastWasSpace) {
            for (let j = 0; j < spaces; j++) {
              if (this.props.nonBreaking) {
                items.push(<span key={`${i}-${j}`}>&nbsp;</span>);
              } else {
                items.push(
                  <span key={`${i}-${j}`} style={{ whiteSpace: 'pre-wrap' }}>
                    {' '}
                  </span>
                );
              }
            }
            lastWasSpace = true;
          }
        }

        // note: we ALWAYS need to add the child, even if it's undefined;
        //       if we don't, it's possible that a re-render could swap
        //       in a new child that impersonates this child and can steal
        //       things like events from it (if the children are buttons)
        items.push(child);

        // note whether or not it was a space
        if (child) {
          lastWasSpace = false;
        }
      }
      return items;
    } else {
      return this.props.children;
    }
  }
}

// set prop types and required-ness
ElementGroup.propTypes = {
  nonBreaking: PropTypes.bool,
  spaces: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};

// set default props
ElementGroup.defaultProps = { nonBreaking: true, spaces: 2 };

export default ElementGroup;
