import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import BasePage from 'common/components/Page';
import Retry from 'common/components/Retry';
import Loading from 'common/components/Loading';
import PageHeader from 'common/components/PageHeader';
import { BreadcrumbsItem } from 'common/components/Breadcrumbs';
import { logout } from 'common/entities/Security/actions';
import { storeOnContext } from 'common/entities/Context/actions';
import { fetchSitesForSelect } from 'common/entities/Site/actions';
import {
  fetchVolunteerById,
  fetchVolunteerInterests,
  fetchVolunteerOrganizationsForSelect
} from 'common/entities/Volunteer/actions';
import VolunteerC from 'common/scenes/Volunteer';
import { sortArray } from 'common/util/index';
import i18n from './i18n.json';
import { getSessionId } from 'common/util/session';

/* Volunteer management. */
class Volunteer extends BasePage {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // because this component is unique in that it can handle both logged
    // in users and users that are not logged in, we need to handle a situation
    // where it's possible that we have a volunteer but do not have a
    // session token; if that happens, log the user out
    if (!getSessionId() && this.props.volunteer) {
      this.props.logout();

      // we are going to skip data loading, so we
      // need to manually flag the load as complete
      this.setState({
        dataLoadError: false,
        dataLoading: false
      });
    } else {
      // refresh the volunteer to start
      this.loadData(this.props);
    }
  }

  loadData(props) {
    // run our loader; this results in the volunteer
    // being updated on the context, so we don't need
    // to do anything with the result
    super.loadData(
      [
        props.volunteer
          ? props.fetchVolunteer(props.volunteer.id)
          : Promise.resolve(),
        props.fetchInterests(),
        this.props.fetchSites(),
        this.props.fetchOrganizations()
      ],
      results => {
        // the results are in the same order as the calls
        const volunteer = results[0];
        const interests = results[1];
        const sites = results[2];
        const organizations = results[3];

        // update it on the context
        if (volunteer) {
          this.props.storeOnContext('volunteer', volunteer);
        }

        // sort the interests
        sortArray(interests, 'name');

        // remap site keys/values to be consistent
        if (sites) {
          for (let i = 0; i < sites.length; i++) {
            sites[i] = { id: sites[i].key, name: sites[i].value };
          }
        }

        // sort the sites
        sortArray(sites, 'name');

        // sort the organizations
        sortArray(organizations, 'value');

        // return the updated state
        return {
          interests: interests,
          sites: sites,
          volunteerOrganizations: organizations
        };
      }
    );
  }

  render() {
    // parent
    super.render();

    // is this a new account?
    let newAccount = !this.props.volunteer || !this.props.volunteer.id;

    // render
    return (
      <div>
        {!newAccount && (
          <div>
            <BreadcrumbsItem
              to={this.props.location.pathname}
              text={this.props.translate('volunteer.header')}
            />
            <PageHeader text={this.props.translate('volunteer.header')} />
          </div>
        )}

        {/* load error? */}
        {super.dataLoadError() && (
          <Retry onRefresh={() => this.loadData(this.props)} />
        )}

        {/* still loading? */}
        {super.dataLoading() && (
          <div className="row">
            <div className="col-12 text-center fsp-content-loading">
              <Loading className="mt-3 mb-3" />
            </div>
          </div>
        )}

        {/* good? */}
        {super.dataLoadSuccess() && (
          <VolunteerC
            volunteer={this.props.volunteer}
            interests={this.state.interests}
            sites={this.state.sites}
            volunteerOrganizations={this.state.volunteerOrganizations}
          />
        )}
      </div>
    );
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // logs the user out
  logout: () => {
    // the logout functionality cleans things up
    dispatch(logout());
  },

  // sets an object on the context
  storeOnContext: (key, data) => {
    return dispatch(storeOnContext(key, data));
  },

  // fetches a volunteer
  fetchVolunteer: volunteerId => {
    return dispatch(fetchVolunteerById(volunteerId));
  },

  // fetches volunteer interests
  fetchInterests: () => {
    return dispatch(fetchVolunteerInterests());
  },

  // fetches all sites
  fetchSites: () => {
    // do the fetch
    return dispatch(fetchSitesForSelect(false));
  },

  // fetches volunteer organizations
  fetchOrganizations: () => {
    return dispatch(fetchVolunteerOrganizationsForSelect());
  }
});

// turn this into a container component
Volunteer = withLocalize(
  connect(
    null,
    mapDispatchToProps
  )(Volunteer)
);

// set prop types and required-ness
Volunteer.propTypes = {
  volunteer: PropTypes.object
};

export default Volunteer;
