import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateVolunteer } from 'common/entities/Volunteer/actions';

import BasePage from 'common/components/Page';
import ChildProtectionPolicyC from 'common/scenes/ChildProtectionPolicy';
import { storeOnContext } from 'common/entities/Context/actions';

/* Child protection policy acceptance. */
class ChildProtectionPolicy extends BasePage {
  render() {
    // parent
    super.render();

    // common policy
    return (
      <ChildProtectionPolicyC
        {...this.props}
        entity={this.props.volunteer}
        updateEntity={updateVolunteer}
        onUpdate={entity => {
          this.props.dispatch(storeOnContext('volunteer', entity));
        }}
      />
    );
  }
}

// turn this into a container component
ChildProtectionPolicy = connect()(ChildProtectionPolicy);

// set prop types and required-ness
ChildProtectionPolicy.propTypes = {
  volunteer: PropTypes.object.isRequired
};

export default ChildProtectionPolicy;
