import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';

import { BasePureComponent } from 'common/components/Base';
import Alert from './components/Alert';
import { clearAlert } from './actions';
import './styles.scss';

/* A group of alerts. */
class Alerts extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // a map of timers for clearing the alerts
    this.timers = new Map();
  }

  /* Times out an alert. */
  timeoutAlert = id => {
    // remove the timer from the map
    this.timers.delete(id);

    // close the alert
    $('#' + id).alert('close');

    // dispatch the timeout
    this.props.onTimeout(id);
  };

  /* Sets a timer for an alert, but only if one doesn't already exist. */
  setTimer = alert => {
    // if we don't have a timer for this alert,
    // and if it's not persistent, start one
    if (!this.timers.has(alert.id)) {
      if (alert.timeout > 0) {
        this.timers.set(
          alert.id,
          setTimeout(() => {
            this.timeoutAlert(alert.id);
          }, alert.timeout)
        );
      }
    }
  };

  render() {
    // parent
    super.render();

    // make sure we have valid alerts (the default state contains an empty alert)
    let valid = [];
    for (let i = 0; i < this.props.alerts.length; i++) {
      if (this.props.alerts[i].id != null && this.props.alerts[i].id > 0) {
        valid.push(this.props.alerts[i]);
      }
    }

    // make sure all alerts have a timer
    for (let i = 0; i < valid.length; i++) {
      this.setTimer(valid[i]);
    }

    // make sure there are no orphan timers; this can happen for manually closed alerts
    for (const entry of this.timers.entries()) {
      const id = entry[0];
      let found = false;
      for (let i = 0; i < valid.length; i++) {
        if (id === valid[i].id) {
          found = true;
          break;
        }
      }
      if (!found) {
        clearTimeout(this.timers[id]);
        this.timers.delete(id);
      }
    }

    // parent
    super.render();

    // render
    return (
      <div className="fsp-alerts">
        {valid.map(alert => (
          <Alert
            key={alert.id}
            id={alert.id}
            flavor={alert.flavor}
            text={alert.text}
            timeout={alert.timeout}
            onClose={() => this.props.onClose(alert.id)}
          />
        ))}
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = state => ({
  alerts: state.alerts
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = dispatch => ({
  // dispatch after a timeout to let the alert fade completely
  onTimeout: id => {
    setTimeout(() => {
      dispatch(clearAlert(id));
    }, 1000);
  },

  // dispatch after a timeout to let the alert fade completely
  onClose: id => {
    setTimeout(() => {
      dispatch(clearAlert(id));
    }, 1000);
  }
});

// turn this into a container component
Alerts = connect(
  mapStateToProps,
  mapDispatchToProps
)(Alerts);

// set prop types and required-ness
Alerts.propTypes = {};

// set default props
Alerts.defaultProps = {};

export default Alerts;
