import React from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { BasePureComponent } from 'common/components/Base';
import ElementGroup from 'common/components/ElementGroup';
import Button from 'common/components/Form/components/Button';
import './styles.scss';

/* Confirm dialog. */
class ConfirmDialog extends BasePureComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div className="container-fluid fsp-confirm-dialog">
        <div className="row">
          <div className="col">
            <h2>{this.props.prompt}</h2>
          </div>
        </div>
        {this.props.question && (
          <div className="row">
            <div className="col">
              <p>{this.props.question}</p>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col text-center">
            {this.props.buttons ? (
              this.props.buttons
            ) : (
              <ElementGroup nonBreaking={false}>
                <Button
                  onClick={() => {
                    // close the dialog
                    this.props.onClose();
                  }}
                  title={
                    this.props.noTitle ? this.props.noTitle : this.props.noLabel
                  }
                >
                  {this.props.noLabel}
                </Button>
                <Button
                  onClick={() => {
                    // close the dialog
                    this.props.onClose();

                    // perform the logic
                    return this.props.onConfirm();
                  }}
                  title={
                    this.props.yesTitle
                      ? this.props.yesTitle
                      : this.props.yesLabel
                  }
                >
                  {this.props.yesLabel}
                </Button>
              </ElementGroup>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// set prop types and required-ness
ConfirmDialog.propTypes = {
  prompt: PropTypes.string.isRequired,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClose: requiredIf(PropTypes.func, props => !props.buttons),
  noLabel: requiredIf(PropTypes.string, props => !props.buttons),
  noTitle: PropTypes.string,
  onConfirm: requiredIf(PropTypes.func, props => !props.buttons),
  yesLabel: requiredIf(PropTypes.string, props => !props.buttons),
  yesTitle: PropTypes.string,
  buttons: PropTypes.array
};

// set default props
ConfirmDialog.defaultProps = {};

export default ConfirmDialog;
