import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { Field } from 'redux-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment/moment';

import { BasePureComponent } from 'common/components/Base';
import { BaseFormComponent } from '..';
import { isTouchDevice } from 'common/util';
import { setFieldError, clearFieldError } from '../../utility';
import * as v from 'app/variables';
import i18n from './i18n.json';
import './styles.scss';

/*
 * A single date and/or timeinput field, along with some of the styling around it.
 * Because of thelibary we're using, we have to duplicate some of the logic
 * from Form.renderField().
 *
 * If using just a date, it must be in the format YYYY-MM-DD. If using a date/time,
 * it should be a Unix timestamp. If using just a time, it should be in the format
 * HH:MM.
 */
class DateTime extends BaseFormComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // for tracking min/max date/times
    this.state = {
      ...this.state,
      includeDate: true,
      includeTime: true,
      minDate: null,
      maxDate: null,
      minTime: null,
      maxTime: null
    };
  }

  static getDerivedStateFromProps(props, state) {
    // if minDate/maxDate are strings, we need to convert them to dates
    let minDate = props.minDate;
    if (minDate && typeof minDate === 'string') {
      try {
        minDate = moment(minDate, 'YYYY-MM-DD').toDate();
      } catch (e) {
        console.error(`Error parsing min date '${minDate}`, e);
        minDate = null;
      }
    }
    let maxDate = props.maxDate;
    if (maxDate && typeof maxDate === 'string') {
      try {
        maxDate = moment(maxDate, 'YYYY-MM-DD').toDate();
      } catch (e) {
        console.error(`Error parsing max date '${maxDate}`, e);
        maxDate = null;
      }
    }

    // if minTime/maxTime are strings, we need to convert them to dates
    let minTime = props.minTime;
    if (minTime && typeof minTime === 'string') {
      try {
        minTime = moment(minTime, 'HH:mm').toDate();
      } catch (e) {
        console.error(`Error parsing min time '${minTime}`, e);
        minTime = null;
      }
    }
    let maxTime = props.maxTime;
    if (maxTime && typeof maxTime === 'string') {
      try {
        maxTime = moment(maxTime, 'HH:mm').toDate();
      } catch (e) {
        console.error(`Error parsing max time '${maxTime}`, e);
        maxTime = null;
      }
    }

    // we have to include either date or time
    let includeDate = props.includeDate;
    let includeTime = props.includeTime;
    if (!includeDate && !includeTime) {
      includeDate = true;
    }

    // bundle it all up
    return {
      includeDate: includeDate,
      includeTime: includeTime,
      minDate: minDate,
      maxDate: maxDate,
      minTime: minTime,
      maxTime: maxTime
    };
  }

  // validates the the date is within a defined range
  validateRange = value => {
    // if we are disabled, don't validate
    if (this.props.disabled) {
      return null;
    }

    // if we have a custom function, invoke it
    let error = null;
    if (this.props.validate) {
      error = this.props.validate(value);
    }

    // if we don't have an error, we check a few things
    if (value && !error) {
      // note that we have to use the parsed format
      const d =
        this.state.includeDate && this.state.includeTime
          ? moment.unix(value)
          : this.state.includeDate
          ? moment(value, 'YYYY-MM-DD', true)
          : moment(value, 'HH:mm', true);
      if (!d.isValid()) {
        error = this.props.translate(
          `dateTime.${
            this.state.includeDate && this.state.includeTime
              ? 'dateTime'
              : this.state.includeDate
              ? 'date'
              : 'time'
          }.error.invalid`
        );
      }

      // make sure it's in the valid range
      if (!error && this.state.minDate) {
        if (d.isBefore(moment(this.state.minDate), 'day')) {
          error = this.props.tooOldError
            ? this.props.tooOldError
            : this.props.translate(
                `dateTime.${
                  this.state.includeDate && this.state.includeTime
                    ? 'dateTime'
                    : this.state.includeDate
                    ? 'date'
                    : 'time'
                }.error.tooOld`
              );
        }
      }
      if (!error && this.state.maxDate) {
        if (d.isAfter(moment(this.state.maxDate), 'day')) {
          error = this.props.tooNewError
            ? this.props.tooNewError
            : this.props.translate(
                `dateTime.${
                  this.state.includeDate && this.state.includeTime
                    ? 'dateTime'
                    : this.state.includeDate
                    ? 'date'
                    : 'time'
                }.error.tooNew`
              );
        }
      }
    }

    // valid?
    if (error) {
      setFieldError(null, this.props.name, error);
      return error;
    } else {
      clearFieldError(null, this.props.name);
      return null;
    }
  };

  render() {
    // parent, for lifecycle logging
    super.render();

    // the user's language drives the formats
    let formatDMY =
      this.props.activeLanguage && this.props.activeLanguage.code !== 'en';

    // date only formats
    let timeFormat = null;
    let displayFormat = formatDMY ? 'dd-MM-yyyy' : 'MM-dd-yyyy';
    let momentFormat = formatDMY ? 'DD-MM-YYYY' : 'MM-DD-YYYY';
    let validatePattern = formatDMY
      ? '^(([0-3][0-9])[/-]([0-1][0-9])[/-]((19|20)([0-9]{2})))$'
      : '^(([0-1][0-9])[/-]([0-3][0-9])[/-]((19|20)([0-9]{2})))$';

    // things change if time is involved
    if (this.state.includeTime) {
      timeFormat = formatDMY ? 'HH:mm' : 'h:mm a';
      const timeMomentFormat = formatDMY ? 'HH:mm' : 'H:mm a';

      // date/time?
      if (this.state.includeDate) {
        // date/time formats
        displayFormat += ` ${timeFormat}`;
        momentFormat += ` ${timeMomentFormat}`;
        validatePattern = formatDMY
          ? '^(([0-3][0-9])[/-]([0-1][0-9])[/-]((19|20)([0-9]{2})))( )(([01]?[0-9]|2[0-3]):[0-5][0-9])$'
          : '^(([0-1][0-9])[/-]([0-3][0-9])[/-]((19|20)([0-9]{2})))( )((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))$';
      } else {
        // time only formats
        displayFormat = timeFormat;
        momentFormat = timeMomentFormat;
        validatePattern = formatDMY
          ? '^(([01]?[0-9]|2[0-3]):[0-5][0-9])$'
          : '^((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))$';
      }
    }

    // render
    return (
      <div
        className={
          this.props.unwrapped
            ? 'has-error'
            : (this.props.colspan && this.props.colspan > 0
                ? `col-${this.props.colbreak ? `${this.props.colbreak}-` : ''}${
                    this.props.colspan
                  }`
                : 'col') + ' has-error'
        }
      >
        <Field
          fieldType={this.props.fieldType}
          size={this.props.size}
          label={this.props.label}
          name={this.props.name}
          autoComplete={
            this.props.autoComplete ? this.props.autoComplete : 'off'
          }
          labelClassName={`col-form-label col-form-label-${this.props.size}`}
          className={`form-control form-control-${this.props.size}`}
          component={this.props.component}
          placeholder={this.props.placeholder}
          tooltip={this.props.tooltip}
          minDate={this.state.includeDate ? this.state.minDate : null}
          maxDate={this.state.includeDate ? this.state.maxDate : null}
          minTime={this.state.includeTime ? this.state.minTime : null}
          maxTime={this.state.includeTime ? this.state.maxTime : null}
          excludeDates={this.props.excludeDates}
          pattern={validatePattern}
          displayFormat={displayFormat}
          timeFormat={timeFormat}
          valueFormat={momentFormat}
          format={value => {
            // back to display format
            if (value) {
              if (this.state.includeDate && this.state.includeTime) {
                return moment.unix(value).format(momentFormat);
              } else if (!this.state.includeDate && this.state.includeTime) {
                return moment(value, 'HH:mm').format(momentFormat);
              } else {
                return moment(value, 'YYYY-MM-DD').format(momentFormat);
              }
            } else {
              return value;
            }
          }}
          includeTime={this.state.includeTime}
          includeDate={this.state.includeDate}
          timeInterval={this.props.timeInterval}
          includeWeekends={this.props.includeWeekends}
          required={this.props.required}
          disabled={this.props.disabled}
          normalize={this.props.normalize}
          parse={value => {
            if (value) {
              // format differs depending on whether or not time is involved
              if (this.state.includeDate && this.state.includeTime) {
                // fringe case; if the user didn't touch the date
                // and simply selected a time, the date will default
                // to today, which may be outside the valid range
                let m = moment(value, momentFormat);
                if (this.state.minDate) {
                  const min = moment(this.state.minDate);
                  if (moment(m).isBefore(min, 'day')) {
                    m.set('year', min.get('year'));
                    m.set('month', min.get('month'));
                    m.set('date', min.get('date'));
                  }
                }

                // make sure the time is within the valid range
                if (this.state.minTime || this.state.maxTime) {
                  const min = this.state.minTime
                    ? moment(this.state.minTime)
                    : null;
                  const max = this.state.maxTime
                    ? moment(this.state.maxTime)
                    : null;
                  if (
                    min &&
                    moment(m)
                      .set('year', 2000)
                      .set('month', 0)
                      .set('date', 1)
                      .startOf('min')
                      .isBefore(
                        min
                          .set('year', 2000)
                          .set('month', 0)
                          .set('date', 1)
                          .startOf('min')
                      )
                  ) {
                    m.set('hour', min.get('hour'));
                    m.set('minute', min.get('minute'));
                  } else if (
                    max &&
                    moment(m)
                      .set('year', 2000)
                      .set('month', 0)
                      .set('date', 1)
                      .startOf('minute')
                      .isAfter(
                        max
                          .set('year', 2000)
                          .set('month', 0)
                          .set('date', 1)
                          .startOf('minute')
                      )
                  ) {
                    m.set('hour', max.get('hour'));
                    m.set('minute', max.get('minute'));
                  }
                }

                // parse to Unix timestamp
                return m.unix();
              } else if (!this.state.includeDate && this.state.includeTime) {
                // make sure the time is within the valid range
                let m = moment(value, momentFormat);
                if (this.state.minTime || this.state.maxTime) {
                  const min = this.state.minTime
                    ? moment(this.state.minTime)
                    : null;
                  const max = this.state.maxTime
                    ? moment(this.state.maxTime)
                    : null;
                  if (
                    min &&
                    moment(m)
                      .set('year', 2000)
                      .set('month', 0)
                      .set('date', 1)
                      .startOf('min')
                      .isBefore(
                        min
                          .set('year', 2000)
                          .set('month', 0)
                          .set('date', 1)
                          .startOf('min')
                      )
                  ) {
                    m.set('hour', min.get('hour'));
                    m.set('minute', min.get('minute'));
                  } else if (
                    max &&
                    moment(m)
                      .set('year', 2000)
                      .set('month', 0)
                      .set('date', 1)
                      .startOf('minute')
                      .isAfter(
                        max
                          .set('year', 2000)
                          .set('month', 0)
                          .set('date', 1)
                          .startOf('minute')
                      )
                  ) {
                    m.set('hour', max.get('hour'));
                    m.set('minute', max.get('minute'));
                  }
                }

                // parse to DB time format
                return m.format('HH:mm');
              } else {
                // parse to DB date format
                return moment(value, momentFormat).format('YYYY-MM-DD');
              }
            } else {
              return value;
            }
          }}
          validate={this.validateRange}
          onFocus={this.props.onFocus}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onDragStart={this.props.onDragStart}
          onDrop={this.props.onDrop}
        />
        {this.props.containerChildren}
      </div>
    );
  }
}

// renderer
const renderDate = ({
  meta: { form, touched, error },
  size,
  input,
  id = null,
  label,
  autoComplete = null,
  labelClassName = '',
  className = '',
  placeholder,
  tooltip = null,
  minDate,
  maxDate,
  minTime,
  maxTime,
  excludeDates,
  pattern,
  timeFormat,
  displayFormat,
  valueFormat,
  includeTime = false,
  timeInterval = 15,
  includeDate = true,
  required = false,
  disabled = false,
  includeWeekends = v.includeWeekends,
  onSelection
}) => {
  // we need to strip 'value' out of input because
  // the DatePicker handles it via 'selected'
  const value = input.value;
  delete input.value;

  // render field
  return (
    <DatePicker
      {...input}
      customInput={
        <DateInput
          form={form}
          size={size}
          label={label}
          labelClassName={labelClassName}
          name={input.name}
          pattern={pattern}
          error={error}
          tooltip={tooltip}
          touched={touched}
        />
      }
      showTimeSelect={includeTime}
      showTimeSelectOnly={includeTime && !includeDate}
      timeIntervals={includeTime ? timeInterval : null}
      selected={value ? moment(value, valueFormat).toDate() : null}
      onSelect={onSelection}
      id={id ? id : input.name}
      disabled={disabled}
      required={required}
      placeholderText={!disabled ? placeholder : null}
      className={className}
      popperClassName={
        includeDate && includeTime
          ? 'fsp-datepicker-datetime'
          : !includeTime
          ? 'fsp-datepicker-date'
          : 'fsp-datepicker-time'
      }
      dateFormat={displayFormat}
      filterDate={
        includeDate && !includeWeekends
          ? date => [1, 2, 3, 4, 5].includes(date.getDay())
          : null
      }
      timeFormat={includeTime ? timeFormat : null}
      minDate={includeDate ? minDate : null}
      maxDate={includeDate ? maxDate : null}
      minTime={includeTime ? minTime : null}
      maxTime={includeTime ? maxTime : null}
      excludeDates={
        excludeDates ? excludeDates.map(date => moment(date).toDate()) : null
      }
      data-html="true"
      title={tooltip}
      autoComplete={autoComplete}
      onFocus={input.onFocus}
      onChange={e => {
        // clear HTML5 errors; this is the only place we
        // can clear field-specific server errors
        clearFieldError(form, input.name);

        // if we have a callback, invoke it
        if (input.onChange) {
          input.onChange(e);
        }
      }}
      onBlur={input.onBlur}
      onDragStart={input.onDragStart}
      onDrop={input.onDrop}
    />
  );
};

// get access to the user's language
DateTime = withLocalize(DateTime);

// set prop types and required-ness
DateTime.propTypes = {
  unwrapped: PropTypes.bool,
  colbreak: PropTypes.string,
  colspan: PropTypes.number,
  size: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  component: PropTypes.func,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  minTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  excludeDates: PropTypes.array,
  includeDate: PropTypes.bool,
  includeTime: PropTypes.bool,
  timeInterval: PropTypes.number,
  includeWeekends: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  containerChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  tooOldError: PropTypes.string,
  tooNewError: PropTypes.string
};

// set default props
DateTime.defaultProps = {
  unwrapped: false,
  colbreak: 'sm',
  size: v.formWidgetSize,
  component: renderDate,
  includeDate: true,
  includeTime: false,
  includeWeekends: true,
  required: false,
  disabled: false
};

export default DateTime;

/* A helper class needed to render the date picker properly. */
class DateInput extends BasePureComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    return (
      <div>
        {/* field label */}
        {this.props.label != null &&
          ((typeof label === 'string' && this.props.label.length > 0) ||
            true) && (
            <label
              htmlFor={this.props.name}
              className={
                (this.props.labelClassName ? this.props.labelClassName : '') +
                (this.props.required && !this.props.disabled
                  ? ' fsp-required'
                  : ' fsp-optional')
              }
            >
              <span>{this.props.label}</span>
            </label>
          )}

        {/* tooltip group */}
        <div
          className={
            this.props.tooltip
              ? `input-group input-group-${this.props.size}`
              : ''
          }
        >
          {/* the field */}
          <input
            id={this.props.id ? this.props.id : this.props.name}
            type="text"
            className={this.props.className}
            name={this.props.name}
            title={this.props.title}
            value={this.props.value}
            placeholder={!this.props.disabled ? this.props.placeholder : null}
            pattern={this.props.pattern}
            autoComplete={this.props.autoComplete}
            disabled={this.props.disabled}
            required={this.props.required}
            onFocus={this.props.onFocus}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            onDragStart={this.props.onDragStart}
            onDrop={this.props.onDrop}
          />

          {/* tooltip (for touch devices) */}
          {this.props.tooltip && isTouchDevice() && (
            <div className="input-group-append">
              <span className="input-group-text">
                <div className="fsp-tooltip">
                  <FontAwesomeIcon icon="question" />
                  <span className="fsp-tooltip-text">{this.props.tooltip}</span>
                </div>
              </span>
            </div>
          )}
        </div>

        {/* field error */}
        {(this.props.touched || this.props.disabled) && this.props.error && (
          <span className="fsp-field-error">{this.props.error}</span>
        )}
      </div>
    );
  }
}

// set prop types and required-ness
DateInput.propTypes = {
  form: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelClassName: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  pattern: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  error: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  touched: PropTypes.bool
};
