import detect from 'browser-detect';
import moment from 'moment/moment';

// for throttling SOSes
let lastSOS = Date.now() - 60 * 1000;

/**
 * Sends an SOS. Throttled to at most one per minute.
 */
export function sendSOS(summary, details) {
  // try to get the most useful details
  if (details.stack) {
    details = details.stack;
  } else if (details.code && details.message) {
    details = 'Code: ' + details.code + '; Message: ' + details.message;
  } else if (typeof details !== 'string') {
    details = JSON.stringify(details);
  }

  // make sure they are safe to embed in the request
  details = escape(details);

  // if there is a summary, prepend it
  if (summary) {
    details = summary + ' - ' + details;
  }

  // don't send if we just sent one
  const elapsed = Date.now() - lastSOS;
  if (elapsed <= 60 * 1000) {
    console.error(
      "We just sent an SOS, so we're not going to send another; what we would have sent:",
      details
    );

    // do nothing else
    return { type: 'NO_OP' };
  } else {
    // note the time
    lastSOS = Date.now();
  }

  // add some useful debugging information
  const browser = detect();
  details += '<p>';
  details += `Time: ${moment().format('MM/DD/YYYY HH:mm:ss.SSS')}<br />`;
  details += `OS: ${browser.os}<br />`;
  details += `Browser: ${browser.name} ${browser.version}<br />`;
  details += `URL: ${window.location}<br />`;
  details += '</p>';

  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'SEND_SOS',
      payload: {
        request: {
          method: 'post',
          url: `/admin/sos?source=${
            process.env.REACT_APP_NAME ? process.env.REACT_APP_NAME : ''
          }`,
          data: {
            details: details
          }
        }
      }
    });
  };
}
