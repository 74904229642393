import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import BasePage from 'common/components/Page';
import { getSessionId } from 'common/util/session';
import FrontDoor from './components/FrontDoor';
import Dashboard from 'app/scenes/Dashboard';
import { extractURLParams } from 'common/util/index';

/* Home page. */
class Home extends BasePage {
  render() {
    // parent
    super.render();

    // logged in?
    if (!getSessionId() || !this.props.volunteer) {
      // front door
      return <FrontDoor {...this.props} />;
    } else {
      // if we have a redirect path, go there
      const redirect = extractURLParams(this.props.match).redirect;
      if (redirect) {
        return <Redirect to={decodeURIComponent(redirect)} />;
      }

      // dashboard
      return <Dashboard {...this.props} />;
    }
  }
}

// turn this into a container component
Home = connect()(Home);

// set prop types and required-ness
Home.propTypes = { volunteer: PropTypes.object };

export default Home;
