import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { BaseFormComponent } from '..';

/* A hidden form field. */
class Hidden extends BaseFormComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <Field
        type="hidden"
        component="input"
        name={this.props.name}
        disabled={this.props.disabled}
      />
    );
  }
}

// set prop types and required-ness
Hidden.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool
};

// set default props
Hidden.defaultProps = { disabled: false };

export default Hidden;
