import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';

import BasePage from 'common/components/Page';
import WidgetContainer from 'common/components/WidgetContainer';
import i18n from './i18n.json';
import './styles.scss';

/* Site calendar widget. */
class LinkList extends BasePage {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // load all necessary data
    this.loadData(this.props);
  }

  loadData(props) {
    // run our loaders
    super.loadData([], results => {
      // return the updated state
      return {};
    });
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <WidgetContainer name={this.props.translate('linkList.name')}>
        <div className="row">
          <div className="col fsp-section-header">
            <p>
              <Translate id="linkList.header" />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col fsp-linklist-options">
            <ul>
              {/* profile */}
              <li className="fsp-linklist-option">
                <Link
                  to="/account"
                  title={this.props.translate('linkList.options.account.title')}
                >
                  <Translate id="linkList.options.account.label" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </WidgetContainer>
    );
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({});

// turn this into a container component
LinkList = withLocalize(
  connect(
    null,
    mapDispatchToProps
  )(LinkList)
);

// set prop types and required-ness
LinkList.propTypes = { volunteer: PropTypes.object.isRequired };

export default LinkList;
