// polyfills
import 'core-js';
import 'raf';
import 'url-search-params-polyfill';
import 'function.name';
import 'webstorage-polyfill-wrapper';

import React from 'react';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { LocalizeProvider } from 'react-localize-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'jquery/dist/jquery';

import ErrorBoundary from './ErrorBoundary';
import App from './App';
import createStore from './store';

// for managing browser history via redux
const history = createBrowserHistory();

// create the store and associated persistor
const { store, persistor } = createStore(history);

// wrap a provider around the application
export default () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <CookiesProvider>
          <LocalizeProvider store={store}>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </LocalizeProvider>
        </CookiesProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);
