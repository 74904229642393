// action types
export const ADD = 'alert/ADD';
export const CLEAR = 'alert/CLEAR';

// we need to keep the IDs unique and predictable; must be positive
let nextId = 1;

// display an alert
export const addAlert = (flavor, text, timeout = 4000) => ({
  type: ADD,
  id: nextId++,
  flavor: flavor,
  text: text,
  timeout: timeout
});

// clear an alert
export const clearAlert = id => ({
  type: CLEAR,
  id: id
});
