import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import RToggle from 'react-toggle';
import 'react-toggle/style.css';

import { BaseFormComponent } from '..';
import { renderField } from '..';
import { clearFieldError } from '../../utility';
import * as v from 'app/variables';
import './styles.scss';

/* A toggle (which is really a checkbox in disguise). */
class Toggle extends BaseFormComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div
        className={
          this.props.unwrapped
            ? 'has-error'
            : (this.props.colspan && this.props.colspan > 0
                ? `col-${this.props.colbreak ? `${this.props.colbreak}-` : ''}${
                    this.props.colspan
                  }`
                : 'col') + ' has-error'
        }
      >
        <Field
          size={this.props.size}
          label={this.props.label}
          name={this.props.name}
          labelClassName={`col-form-label col-form-label-${this.props.size}`}
          className={`form-control form-control-${this.props.size}`}
          component={this.props.component}
          tooltip={this.props.tooltip}
          disabled={this.props.disabled}
          onFocus={this.props.onFocus}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onDragStart={this.props.onDragStart}
          onDrop={this.props.onDrop}
        />
        {this.props.containerChildren}
      </div>
    );
  }
}

// renderer
const renderToggle = ({
  meta: { form, touched, error },
  input,
  size,
  label,
  labelClassName = '',
  tooltip = null,
  disabled = false
}) => {
  if (input.value) {
    if (typeof value !== 'boolean') {
      // keep the value
    } else {
      input.value = input.value.toString().toLowerCase() === 'true';
    }
  } else {
    input.value = false;
  }

  return renderField(
    size,
    input.name,
    label,
    disabled,
    false,
    touched,
    error,
    labelClassName,
    null, // intentionally null; tooltips don't look good on toggles
    <div className={`pt-0 form-control-${size}`}>
      <RToggle
        checked={input.value}
        disabled={disabled}
        icons={false}
        className="fsp-form-toggle"
        title={tooltip ? tooltip : ''}
        onFocus={input.onFocus}
        onChange={e => {
          // clear HTML5 errors; this is the only place we
          // can clear field-specific server errors
          clearFieldError(form, input.name);

          // if we have a callback, invoke it
          if (input.onChange) {
            input.onChange(e);
          }
        }}
        onBlur={input.onBlur}
        onDragStart={input.onDragStart}
        onDrop={input.onDrop}
      />
    </div>
  );
};

// set prop types and required-ness
Toggle.propTypes = {
  unwrapped: PropTypes.bool,
  colbreak: PropTypes.string,
  colspan: PropTypes.number,
  size: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  component: PropTypes.func,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  containerChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};

// set default props
Toggle.defaultProps = {
  unwrapped: false,
  colbreak: 'sm',
  size: v.formWidgetSize,
  component: renderToggle,
  disabled: false
};

export default Toggle;
