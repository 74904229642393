import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';

import { BaseFormComponent } from '..';
import { renderField } from '..';
import { clearFieldError } from '../../utility';
import { escapeRegexCharacters } from 'common/util';
import * as v from 'app/variables';
import './styles.scss';

// Derived from https://github.com/moroshko/autosuggest-highlight/pull/12.
// We removed diacritic support. Can be removed if the PR is ever merged.
function match(text, query, options) {
  // options
  options = {
    insideWords: false,
    findAllOccurrences: false,
    requireMatchAll: false,
    ...options
  };

  // http://www.ecma-international.org/ecma-262/5.1/#sec-15.10.2.6
  const wordCharacterRegex = /[a-z0-9_]/i;

  // white spaces
  const whitespacesRegex = /\s+/;

  // find all matches
  return query
    .trim()
    .split(whitespacesRegex)
    .filter(function(word) {
      // if query is blank, we'll get an empty string here; filter it out
      return word.length > 0;
    })
    .reduce(function(result, word) {
      const wordLen = word.length;
      const prefix =
        !options.insideWords && wordCharacterRegex.test(word[0]) ? '\\b' : '';
      const regex = new RegExp(prefix + escapeRegexCharacters(word), 'i');
      let occurrence, index;

      occurrence = regex.exec(text);
      if (options.requireMatchAll && occurrence === null) {
        text = '';
        return [];
      }

      while (occurrence) {
        index = occurrence.index;
        result.push([index, index + wordLen]);

        // replace what we just found with spaces so we don't find it again
        text =
          text.slice(0, index) +
          new Array(wordLen + 1).join(' ') +
          text.slice(index + wordLen);

        if (!options.findAllOccurrences) {
          break;
        }

        occurrence = regex.exec(text);
      }

      return result;
    }, [])
    .sort(function(match1, match2) {
      return match1[0] - match2[0];
    });
}

/* A single form field, along with some of the styling around it. */
class Text extends BaseFormComponent {
  constructor(props) {
    // parent
    super(props);

    // for auto-capitalization
    this.state = {
      ...this.state,
      autoCapitalized: false
    };

    // for suggestions
    this.state = {
      ...this.state,
      suggestions: []
    };
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div
        className={
          this.props.unwrapped
            ? 'has-error'
            : (this.props.colspan && this.props.colspan > 0
                ? `col-${this.props.colbreak ? `${this.props.colbreak}-` : ''}${
                    this.props.colspan
                  }`
                : 'col') + ' has-error'
        }
      >
        <Field
          type={this.props.type}
          step={this.props.step}
          size={this.props.size}
          label={this.props.label}
          name={this.props.name}
          autoComplete={this.props.autoComplete}
          labelClassName={`col-form-label col-form-label-${this.props.size}`}
          className={`form-control form-control-${this.props.size}`}
          component={this.props.component}
          placeholder={this.props.placeholder}
          tooltip={this.props.tooltip}
          minLength={this.props.minLength}
          maxLength={this.props.maxLength}
          min={this.props.min}
          max={this.props.max}
          pattern={this.props.pattern}
          required={this.props.required}
          disabled={this.props.disabled}
          format={this.props.format}
          normalize={value => {
            // auto-trim start?
            if (
              this.props.autoTrimStart &&
              value &&
              typeof value === 'string'
            ) {
              // trim start
              value = value.trimStart();
            }

            // auto-trim end?
            if (this.props.autoTrimEnd && value && typeof value === 'string') {
              // trim end
              value = value.trimEnd();
            }

            // auto-capitalize?
            if (
              this.props.autoCapitalize &&
              !this.state.autoCapitalized &&
              value &&
              typeof value === 'string' &&
              value.length === 1
            ) {
              // capitalize first letter
              value = value.charAt(0).toUpperCase() + value.slice(1);

              // we only do it once
              this.setState({ autoCapitalized: true });
            }

            // null out empty strings
            if (typeof value === 'string' && value.length === 0) {
              value = null;
            }

            // if a string, replace curly quotes
            if (value && typeof value === 'string') {
              value = value
                .replace(/‘/g, "'")
                .replace(/’/g, "'")
                .replace(/“/g, '"')
                .replace(/”/g, '"')
                .replace(/`/g, "'")
                .replace(/´/g, "'");
            }

            // invoke passed in function
            return this.props.normalize ? this.props.normalize(value) : value;
          }}
          parse={this.props.parse}
          validate={this.props.validate}
          onKeyDown={this.props.onKeyDown}
          onKeyUp={this.props.onKeyUp}
          onFocus={this.props.onFocus}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onDragStart={this.props.onDragStart}
          onDrop={this.props.onDrop}
          setField={this.props.setField}
          suggestions={this.props.suggestions}
          getState={() => this.state}
          setState={state => this.setState(state)}
        />
        {this.props.containerChildren}
      </div>
    );
  }
}

// renderer
const renderText = ({
  meta: { form, touched, error },
  size,
  input,
  id = null,
  type,
  step,
  label,
  autoComplete = null,
  labelClassName = '',
  className = '',
  placeholder,
  tooltip = null,
  minLength,
  maxLength,
  min,
  max,
  pattern,
  required = false,
  disabled = false,
  onKeyDown = null,
  onKeyUp = null,
  setField = () => {},
  suggestions = [],
  getState,
  setState
}) => {
  // capture state
  const state = getState();

  // the input field
  const field = (
    <input
      {...input}
      ref={input => setField(input)}
      id={id ? id : input.name}
      disabled={disabled}
      required={required}
      placeholder={!disabled ? placeholder : null}
      type={type === 'emailOrPhone' ? 'text' : type}
      step={step}
      className={`${className ? className : ''} ${
        (type === 'email' || type === 'phone' || type === 'emailOrPhone') &&
        input.value &&
        input.value !== '' &&
        disabled
          ? 'fsp-clickable'
          : ''
      }`}
      pattern={pattern}
      min={min}
      max={max}
      minLength={minLength}
      maxLength={maxLength}
      data-html="true"
      title={tooltip}
      autoComplete={
        suggestions && suggestions.length > 0 ? 'off' : autoComplete
      }
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onFocus={input.onFocus}
      onChange={e => {
        // clear HTML5 errors; this is the only place we
        // can clear field-specific server errors
        clearFieldError(form, input.name);

        // if we have a callback, invoke it
        if (input.onChange) {
          input.onChange(e);
        }
      }}
      onBlur={input.onBlur}
      onDragStart={input.onDragStart}
      onDrop={input.onDrop}
    />
  );

  // render field
  let rendered = renderField(
    size,
    input.name,
    label,
    disabled,
    required,
    touched,
    error,
    labelClassName,
    tooltip,
    field,
    (type === 'email' || type === 'phone' || type === 'emailOrPhone') &&
      input.value &&
      input.value !== '' &&
      disabled
      ? () =>
          (type === 'email' || type === 'emailOrPhone') &&
          input.value.includes('@')
            ? window.open(`mailto:${input.value}`)
            : window.location.assign(`tel:${input.value}`)
      : null
  );

  // auto-suggest?
  if (suggestions && suggestions.length > 0) {
    // we need a copy
    const wrappedRendered = React.cloneElement(rendered);

    // wrap it
    rendered = (
      <Autosuggest
        suggestions={state.suggestions}
        onSuggestionsFetchRequested={({ value }) => {
          // regex for filtering
          const regex = new RegExp(
            `^(.*)${escapeRegexCharacters(value.trim())}(.*)`,
            'i'
          );

          // load async
          setTimeout(() => {
            const filtered = suggestions.filter(suggestion =>
              regex.test(suggestion.value)
            );

            // rendering a large number of suggestions is not
            // performant, so we (arbirarily) limit the number
            // of results to 20
            setState({
              suggestions: filtered.length <= 20 ? filtered : []
            });
          }, 0);
        }}
        onSuggestionsClearRequested={() =>
          setState({
            suggestions: []
          })
        }
        renderSuggestion={(suggestion, { query }) => {
          const matches = match(suggestion.value, query, {
            insideWords: true,
            findAllOccurrences: true
          });
          const parts = AutosuggestHighlightParse(suggestion.value, matches);

          return (
            <span>
              {parts.map((part, index) => {
                const className = part.highlight
                  ? 'react-autosuggest__suggestion-match'
                  : null;

                return (
                  <span className={className} key={index}>
                    {part.text}
                  </span>
                );
              })}
            </span>
          );
        }}
        getSuggestionValue={suggestion => suggestion.value}
        onSuggestionSelected={(event, { suggestionValue, method }) => {
          // set the value on the form
          input.onChange(suggestionValue);

          // eat 'enter' presses
          if (method === 'enter') {
            event.preventDefault();
          }
        }}
        renderInputComponent={inputProps => {
          // use the original input element, adding the new props
          return React.cloneElement(wrappedRendered, { ...inputProps });
        }}
        inputProps={{
          value: input.value,
          onChange: (event, { newValue }) => {
            // this function must be defined, but
            // we don't need to do anything here
          }
        }}
      />
    );
  }

  return rendered;
};

// set prop types and required-ness
Text.propTypes = {
  unwrapped: PropTypes.bool,
  colbreak: PropTypes.string,
  colspan: PropTypes.number,
  size: PropTypes.string,
  type: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  component: PropTypes.func,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pattern: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  format: PropTypes.func,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  autoCapitalize: PropTypes.bool,
  autoTrimStart: PropTypes.bool,
  autoTrimEnd: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  setField: PropTypes.func,
  suggestions: PropTypes.array,
  containerChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};

// set default props
Text.defaultProps = {
  unwrapped: false,
  colbreak: 'sm',
  size: v.formWidgetSize,
  type: 'text',
  autoCapitalize: false,
  autoTrimStart: true,
  autoTrimEnd: false,
  component: renderText,
  required: false,
  disabled: false
};

export default Text;
