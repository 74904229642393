// must come first
import outdatedBrowserRework from 'outdated-browser-rework';
import 'outdated-browser-rework/dist/style.css';
import queryString from 'query-string';

// third-party imports
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { withCookies } from 'react-cookie';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faQuestion,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faTimes,
  faPlus,
  faMinus,
  faMinusCircle,
  faArrowUp,
  faArrowDown,
  faCheck,
  faSignOutAlt,
  faLanguage,
  faCaretRight,
  faExclamation,
  faExclamationCircle,
  faInfoCircle,
  faUser,
  faPencilAlt,
  faTrashAlt,
  faHome,
  faSearch,
  faCalendarAlt,
  faUpload,
  faDownload,
  faEye,
  faClock,
  faWindowMinimize,
  faWindowMaximize,
  faEnvelope,
  faComment,
  faCommentAlt,
  faSync,
  faFileExcel,
  faFilePdf
} from '@fortawesome/free-solid-svg-icons';

// our imports
import { BasePureComponent } from './components/Base';
import ScrollToTop from './components/ScrollToTop';
import Alerts from './components/Alerts';
import Busy from './components/Busy';
import SessionChecker from './components/SessionChecker';
import i18n from './i18n.json';

// callback to application
import AppApp from '../app/App';

// load the Font Awesome icons that we use
library.add(
  faQuestion,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faTimes,
  faPlus,
  faMinus,
  faMinusCircle,
  faArrowUp,
  faArrowDown,
  faCheck,
  faSignOutAlt,
  faLanguage,
  faCaretRight,
  faExclamation,
  faExclamationCircle,
  faInfoCircle,
  faUser,
  faPencilAlt,
  faTrashAlt,
  faHome,
  faSearch,
  faCalendarAlt,
  faUpload,
  faDownload,
  faEye,
  faClock,
  faWindowMinimize,
  faWindowMaximize,
  faEnvelope,
  faComment,
  faCommentAlt,
  faSync,
  faFileExcel,
  faFilePdf
);

// for development, patch in why-did-you-render
if (false && process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

/**
 * The main application.
 */
class App extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // see if we have a language preference
    const language = window.$localStorage.getItem('language');
    if (!language) {
      // default to English
      window.$localStorage.setItem('language', 'en');
    }

    // initialize i18n
    props.initialize({
      languages: [
        { name: 'english', code: 'en' },
        { name: 'spanish', code: 'es' }
      ],
      translation: i18n,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage: window.$localStorage.getItem('language')
      }
    });
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // fire up the outdated browser check
    outdatedBrowserRework({
      browserSupport: {
        Chrome: 48,
        Edge: false,
        Safari: 9.1,
        'Mobile Safari': 9.1,
        Firefox: 45,
        Opera: 35,
        Vivaldi: 1,
        IE: 11
      },
      requireChromeOnAndroid: false,
      isUnknownBrowserOK: true,
      messages: {
        en: {
          outOfDate: `<span style="text-transform: none;">That's a really old browser you've got there!</span>`,
          unsupported: `<span style="text-transform: none;">That's an unsupported browser you've got there!</span>`,
          update: {
            web:
              '<span style="text-transform: none; font-size: 1.2rem; line-height: 1.2;">' +
              "There is a good chance that the site won't look or function correctly in your browser. " +
              "You can try, but you've been warned. Your best bet is to use a modern version of a " +
              'supported browser: Chrome, Safari, Firefox or Opera. That would ' +
              'make us happy, and we think it will make you happy too.' +
              '</span>',
            googlePlay:
              '<span style="text-transform: none; line-height: 1.2;">To make sure the site works properly, please install ' +
              'a modern browser from Google Play</span>',
            appStore:
              '<span style="text-transform: none; line-height: 1.2;">To make sure the site works properly, please ' +
              'update iOS from the Settings App</span>'
          },
          url: 'http://outdatedbrowser.com/',
          callToAction: 'Update my browser now',
          close: 'Close'
        }
      }
    });
  }

  render() {
    // parent
    super.render();

    // parse query params, if any
    const queryParams = this.props.searchQuery
      ? queryString.parse(this.props.searchQuery)
      : {};

    // render
    return (
      <div>
        {/* common components */}
        <div>
          <Busy />
          <Alerts />
        </div>

        {/* application content */}
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ScrollToTop>
            <SessionChecker>
              <AppApp {...this.props} queryParams={queryParams} />
            </SessionChecker>
          </ScrollToTop>
        </BrowserRouter>
        <div className="fsp-outdated">
          <div id="outdated" />
        </div>
      </div>
    );
  }
}

// enable cookies
App = withCookies(App);

// map state to properties relevant to this component
const mapStateToProps = state => ({
  // a unique token that can force a refresh by changing
  token: state.context.token,

  // current search query
  searchQuery: window.location ? window.location.search : null
});

// turn this into a container component
export default withLocalize(connect(mapStateToProps)(App));
