import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import BasePage from 'common/components/Page';
import { storeOnContext } from 'common/entities/Context/actions';
import { getSessionId } from 'common/util/session';
import Retry from 'common/components/Retry';
import Loading from 'common/components/Loading';

/*
 * Ensures that logged in sessions contain necessary data for components to proceed.
 */
class DataGuard extends BasePage {
  constructor(props) {
    super(props);

    // we don't load our data on mount like most components do
    this.state = {
      ...this.state,
      dataLoading: false,
      tries: 0
    };
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // load data
    this.loadData(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    // parent
    super.componentDidUpdate(prevProps, prevState);

    // load data
    this.loadData(this.props);
  }

  loadData(props, force = false) {
    // it's possible we're still loading or that we tried and failed
    if (!force && (super.dataLoading() || super.dataLoadError())) {
      return;
    }

    // the loaders we'll run
    const loaders = [];

    // see what we should load
    if (props.data) {
      for (let key in props.data) {
        // do we have this data?
        if (!props[key]) {
          // data descriptor
          const o = props.data[key];

          // load it
          if (o.fetcher) {
            // some fetchers only run if authenticated
            if (!o.authenticated || getSessionId()) {
              loaders.push(
                props
                  .dispatch(o.fetcher())
                  .then(result => {
                    // transform?
                    if (o.transformer) {
                      result = o.transformer(result);
                    }

                    // put it on the context
                    console.debug(
                      "Storing on context under '" + key + "'",
                      result
                    );
                    this.props.dispatch(storeOnContext(key, result));
                  })
                  .catch(e => {
                    // this is kind of silly, but the user isn't aware we've
                    // tried again unless we introduce an artificial delay
                    return new Promise((_, reject) =>
                      setTimeout(
                        () => {
                          reject(e);
                        },
                        force ? 1000 : 0
                      )
                    );
                  })
              );
            }
          } else {
            // nothing we can do about it
            console.error(
              "We don't have '" +
                key +
                "', but we weren't provided a way to fetch it"
            );
          }
        }
      }
    }

    // if we're going to try to load something, note that
    if (loaders.length > 1) {
      this.setState({ tries: this.state.tries + 1 });
    }

    // go
    super.loadData(loaders);
  }

  render() {
    // parent
    super.render();

    // do we have everything we need?
    let ready = true;
    if (this.props.data) {
      for (let key in this.props.data) {
        // if required, do we have this data?
        if (this.props.data[key].required && !this.props[key]) {
          // if this data is only accesible when authenticated,
          // make sure we're logged in before we declare it missing
          if (!this.props.data[key].authenticated || getSessionId()) {
            ready = false;
            break;
          }
        }
      }
    }

    return (
      <>
        {ready ? (
          <>
            {/* good */}
            {React.cloneElement(this.props.children, {
              ...this.props,
              ...this.props.children.props
            })}
          </>
        ) : !super.dataLoadError() || super.dataLoading() ? (
          <>
            {/* still loading */}
            <div className="text-center fsp-content-loading">
              <Loading className="mt-3 mb-3" />
            </div>
          </>
        ) : (
          <>
            {/* error */}
            <Retry onRefresh={() => this.loadData(this.props, true)} />
          </>
        )}
      </>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // directly map expected data
  ...(expected => {
    const o = {};
    for (let key in expected) {
      o[key] = state.context[key];
    }
    return o;
  })(ownProps.data)
});

// turn this into a container component
DataGuard = withLocalize(connect(mapStateToProps)(DataGuard));

// set prop types and required-ness
DataGuard.propTypes = {
  children: PropTypes.element.isRequired,
  data: PropTypes.object.isRequired
};

export default DataGuard;
