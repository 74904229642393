import React from 'react';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';

import { BasePureComponent } from 'common/components/Base';
import MessageBox from 'common/components/MessageBox';
import i18n from './i18n.json';
import './styles.scss';

/* A retry prompt. */
class Retry extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <MessageBox flavor={this.props.flavor}>
        {!this.props.messageText && <Translate id="retry.error" />}{' '}
        {this.props.messageText && this.props.messageText}{' '}
        <span className="fsp-retry-message-link" onClick={this.props.onRefresh}>
          {!this.props.retryText && <Translate id="retry.tryAgain" />}
          {this.props.retryText && this.props.retryText}
        </span>
        {'.'}
      </MessageBox>
    );
  }
}

// localize
Retry = withLocalize(Retry);

// set prop types and required-ness
Retry.propTypes = {
  flavor: PropTypes.string,
  messageText: PropTypes.string,
  retryText: PropTypes.string,
  onRefresh: PropTypes.func.isRequired
};

// set default props
Retry.defaultProps = {
  flavor: 'error'
};

export default Retry;
