import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import { BasePureComponent } from 'common/components/Base';
import Text from 'common/components/Form/components/Text';
import { formatPhone, normalizeNumber } from 'common/util/formatters';
import i18n from './i18n.json';

/* Emergency contact information. */
class EmergencyContact extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <div>
        <div className="form-row form-group">
          {/* emergencyContactName */}
          <Text
            colspan={6}
            label={this.props.translate(
              'emergencyContactForm.field.emergencyContactName.label',
              this.props.i18nData
            )}
            name="emergencyContactName"
            placeholder={this.props.translate(
              'emergencyContactForm.field.emergencyContactName.label',
              this.props.i18nData
            )}
            tooltip={this.props.translate(
              'emergencyContactForm.field.emergencyContactName.title',
              this.props.i18nData
            )}
            maxLength={64}
            autoCapitalize
            required
            disabled={this.props.readOnly || this.props.submitting}
          />

          {/* emergencyContactPhone */}
          <Text
            colspan={4}
            type="phone"
            label={this.props.translate(
              'emergencyContactForm.field.emergencyContactPhone.label'
            )}
            name="emergencyContactPhone"
            placeholder={this.props.translate(
              'emergencyContactForm.field.emergencyContactPhone.label'
            )}
            tooltip={this.props.translate(
              'emergencyContactForm.field.emergencyContactPhone.title'
            )}
            format={formatPhone}
            normalize={value => normalizeNumber(value)}
            minLength={12}
            maxLength={12}
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            required
            disabled={this.props.readOnly || this.props.submitting}
          />
        </div>

        <div className="form-row form-group">
          {/* emergencyContactRelationship */}
          <Text
            colspan={6}
            label={this.props.translate(
              'emergencyContactForm.field.emergencyContactRelationship.label',
              this.props.i18nData
            )}
            name="emergencyContactRelationship"
            placeholder={this.props.translate(
              'emergencyContactForm.field.emergencyContactRelationship.label',
              this.props.i18nData
            )}
            tooltip={this.props.translate(
              'emergencyContactForm.field.emergencyContactRelationship.title',
              this.props.i18nData
            )}
            maxLength={32}
            autoCapitalize
            required
            disabled={this.props.readOnly || this.props.submitting}
          />
        </div>
      </div>
    );
  }
}

// turn this into a container component
EmergencyContact = withLocalize(connect()(EmergencyContact));

// set prop types and required-ness
EmergencyContact.propTypes = {
  form: PropTypes.string.isRequired,
  admin: PropTypes.bool,
  addition: PropTypes.bool,
  readOnly: PropTypes.bool,
  submitting: PropTypes.bool,
  isRestricted: PropTypes.bool,
  translations: PropTypes.object
};

// set default props
EmergencyContact.defaultProps = {
  admin: false,
  addition: false,
  readOnly: false,
  submitting: false,
  isRestricted: false
};

export default EmergencyContact;
