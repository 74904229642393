import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  reset,
  initialize,
  reduxForm,
  formValueSelector,
  clearSubmitErrors,
  stopSubmit
} from 'redux-form';
import { Translate, withLocalize } from 'react-localize-redux';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BaseEditForm, initializeOtherField, processErrors } from 'common/components/Form';
import HasPermission from 'common/components/HasPermission';
import { clone, scrollToAnchor } from 'common/util';
import Button from 'common/components/Form/components/Button';
import ElementGroup from 'common/components/ElementGroup';
import MessageBox from 'common/components/MessageBox';
import Instructions from './components/Instructions';
import Personal from './components/Personal';
import Address from './components/Address';
import Contact from './components/Contact';
import EmergencyContact from './components/EmergencyContact';
import VolunteerOrganization from './components/VolunteerOrganization';
import Interests from './components/Interests';
import SiteAffiliations from './components/SiteAffiliations';
import Credentials from './components/Credentials';
import Rights from './components/Rights';
import Consent from './components/Consent';
import ConfirmDialog from 'common/components/ConfirmDialog';
import { checkValidity } from 'common/components/Form/utility';
import i18n from './i18n.json';
import i18nPersonal from './components/Personal/i18n.json';

/* Account form. Can be presented either as a wizard or a single page. */
class VolunteerForm extends BaseEditForm {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }

    // for tracking steps in wizard
    this.state = { ...this.state, step: 0 };

    // steps in the wizard
    this.STEPS = [
      { key: 'instructions', component: Instructions },
      { key: 'personal', component: Personal },
      { key: 'address', component: Address },
      { key: 'contact', component: Contact },
      { key: 'emergencyContact', component: EmergencyContact },
      { key: 'volunteerOrganization', component: VolunteerOrganization },
      { key: 'interests', component: Interests },
      { key: 'siteAffiliations', component: SiteAffiliations },
      { key: 'credentials', component: Credentials }
    ];

    // if an admin, allow level selection
    if (props.admin) {
      this.STEPS.push({ key: 'rights', component: Rights });
    }

    // if not in admin mode and an addition, or if in admin mode and not an addition
    if ((!props.admin && props.addition) || (props.admin && !props.addition)) {
      this.STEPS.push({ key: 'consent', component: Consent });
    }

    // freeze them
    this.STEPS = Object.freeze(this.STEPS);
  }

  componentDidUpdate(prevProps, prevState) {
    // parent
    super.componentDidUpdate(prevProps, prevState);

    // sanity check; shouldn't happen, but better safe than sorry
    if (this.state.step < 0) {
      this.setState({ step: 0 });
    } else if (this.state.step > this.STEPS.length) {
      this.setState({ step: 0 });
    }
  }

  previous() {
    // go to the previous step?
    if (this.state.step > 0) {
      // previous step
      this.setState({ step: this.state.step - 1 });

      // get back to the top
      scrollToAnchor(this.props.form, this.props.inModal);
    }
  }

  next(values, submit = false) {
    // go to the next step?
    if (!submit) {
      // next step
      this.setState({ step: this.state.step + 1 });

      // get back to the top
      scrollToAnchor(this.props.form, this.props.inModal);
    } else {
      // submit
      return this.props
        .onSubmit(values)
        .then(() => {
          // no longer editing
          this.setState({ currentlyEditing: null });
        })
        .catch(e => {
          // process the error(s)
          processErrors(
            e,
            this.props.translate,
            this.props.dispatch,
            'volunteerForm.error.generic',
            (error, dispatch) => {
              // handle errors specific to this form
              switch (error.code) {
                case 2:
                  switch (error.field) {
                    case 'email':
                      // email already exists
                      return {
                        [error.field]: this.props.translate(
                          'volunteerForm.error.duplicateEmail'
                        ),
                        fieldError: true
                      };
                    default:
                      break;
                  }
                  break;
                default:
                  break;
              }

              return null;
            }
          );
        })
        .finally(() => {
          // get back to the top
          scrollToAnchor(this.props.form, this.props.inModal);
        });
    }
  }

  render() {
    // parent
    super.render();

    // render all steps
    const renderAllSteps = (confirmation = false) => {
      let steps = [];
      this.STEPS.forEach((s, i) => {
        if ('credentials' === this.STEPS[i].key) {
          // we ignore password creation and confirmation
        } else if ('instructions' === this.STEPS[i].key) {
          // we ignore the instructions
        } else {
          // add it
          steps.push(renderStep(i, confirmation));
        }
      });

      return steps;
    };

    // render a single step
    const renderStep = (i, confirmation = false) => {
      const Step = this.STEPS[i].component;
      return (
        <section key={i}>
          {confirmation && (
            <div
              className="text-right fsp-section-edit fsp-inline-link"
              onClick={() => {
                // clear any form errors
                this.props.dispatch(clearSubmitErrors(this.props.form));
                this.props.dispatch(stopSubmit(this.props.form));

                // move to the desired step
                this.setState({ step: i });
              }}
            >
              <Translate id="volunteerForm.editSection" />
            </div>
          )}
          <Step
            form={this.props.form}
            admin={this.props.admin}
            addition={this.props.addition}
            interests={this.props.interests}
            sites={this.props.sites}
            volunteerOrganizations={this.props.volunteerOrganizations}
            readOnly={confirmation || !this.state.currentlyEditing}
            submitting={this.props.submitting}
            isRestricted={
              !this.props.admin &&
              this.state.currentlyEditing &&
              this.state.currentlyEditing !== 'NEW'
                ? true
                : false
            }
            signChildProtectionPolicy={this.props.signChildProtectionPolicy}
            onVolunteerUpdate={volunteer => {
              // reinitialize the form
              this.props.reinitializeForm(volunteer);

              // if we have a callback, fire it
              if (this.props.onVolunteerUpdate) {
                this.props.onVolunteerUpdate(volunteer);
              }
            }}
            translations={this.props.translations}
          />
        </section>
      );
    };

    // are we on the last step (note that we're always on
    // the last step if not in wizard mode)?
    const lastStep =
      this.state.step === this.STEPS.length || !this.props.wizard;

    // current progress
    const progress = (() => {
      switch (this.state.step) {
        case 0:
          return 0;
        default:
          return (100 / this.STEPS.length) * this.state.step;
      }
    })();

    // does the current step have any fields (n/a for non-wizard)?
    const hasFields =
      lastStep ||
      (typeof this.state.step !== 'undefined' &&
        this.STEPS[this.state.step] &&
        this.STEPS[this.state.step].key !== 'instructions')
        ? true
        : false;

    // the consent step is screwy; we can't validate it because there are no visible fields
    let consentGranted = true;
    if (
      typeof this.state.step !== 'undefined' &&
      this.STEPS[this.state.step] &&
      this.STEPS[this.state.step].key === 'consent'
    ) {
      consentGranted = this.props.childProtectionPolicySignature ? true : false;
    }

    // interests are similar; we only consider them valid if at least one is selected
    let interestSelected = true;
    if (
      !this.props.wizard ||
      (typeof this.state.step !== 'undefined' &&
        this.STEPS[this.state.step] &&
        this.STEPS[this.state.step].key === 'interests')
    ) {
      if (this.props.formInterests) {
        let atLeastOne = false;

        // the form properties can be in one of two formats: they can be
        // an array of booleans keyed by ID or they can be an object
        // with IDs as the keys and booleans as the values
        if (Array.isArray(this.props.formInterests)) {
          for (let i = 0; i < this.props.formInterests.length; i++) {
            if (this.props.formInterests[i]) {
              atLeastOne = true;
              break;
            }
          }
        } else {
          for (let key in this.props.formInterests) {
            if (this.props.formInterests[key]) {
              atLeastOne = true;
              break;
            }
          }
        }

        interestSelected = atLeastOne;
      } else {
        interestSelected = false;
      }
    }

    // render
    return (
      <Form
        id={this.props.form}
        className="fsp-form"
        onSubmit={this.props.handleSubmit(values =>
          this.next(values, lastStep)
        )}
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        <div className="card">
          {/* hide the header when an admin */}
          {!this.props.admin && (
            <div className="card-header">
              {/* if not in wizard mode, use a generic header */}
              {!this.props.wizard && (
                <Translate id="volunteerForm.profileHeader" />
              )}

              {/* if in wizard mode and not on the last step, use the current step's header */}
              {this.props.wizard && !lastStep && (
                <Translate
                  id={
                    `volunteerForm.componentHeader.` +
                    this.STEPS[this.state.step].key
                  }
                />
              )}

              {/* if in wizard mode and on the last step, use the confirmation header */}
              {this.props.wizard && lastStep && (
                <Translate id="volunteerForm.confirmationHeader" />
              )}
            </div>
          )}

          {/* if in wizard mode, a progress bar */}
          {this.props.wizard && (
            <div className="progress fsp-wizard-progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: progress + '%' }}
                aria-valuenow={progress / 100}
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
          )}

          <div className="card-body">
            {/* errors */}
            {this.props.error && (
              <div className="has-error">
                <div className="fsp-form-error">{this.props.error}</div>
              </div>
            )}

            {/* if not in wizard mode, and if editing, show a message */}
            {!this.props.admin &&
              !this.props.wizard &&
              (this.state.currentlyEditing &&
                this.state.currentlyEditing !== 'NEW') && (
                <MessageBox flavor="info">
                  <Translate id="volunteerForm.limitedEdit" />
                </MessageBox>
              )}

            {/* if not in wizard mode, render all of the components at once */}
            {!this.props.wizard && renderAllSteps()}

            {/* if in wizard mode and not on the last step, render the current step */}
            {this.props.wizard && !lastStep && renderStep(this.state.step)}

            {/* if in wizard mode and on the last step, render the confirmation view */}
            {this.props.wizard && lastStep && renderAllSteps(true)}
          </div>

          <HasPermission permissions="manageVolunteers">
            <div className="card-footer d-print-none">
              <div className="form-row">
                <div className="col-5 text-left">
                  {/* cancel button */}
                  {this.state.currentlyEditing && (
                    <Button
                      onClick={() => {
                        // if editing, reset the form
                        if (!this.props.addition) {
                          // reset the form and state
                          this.props.resetForm();
                          this.setState({ currentlyEditing: null });

                          // go to the top
                          scrollToAnchor(this.props.form, this.props.inModal);
                        } else {
                          this.props.onCancel();
                        }
                      }}
                      title={this.props.translate(
                        'volunteerForm.button.cancel' +
                          (this.props.addition ? 'Add' : 'Edit') +
                          '.title'
                      )}
                      alternate={<FontAwesomeIcon icon="times" />}
                    >
                      <Translate
                        id={
                          'volunteerForm.button.cancel' +
                          (this.props.addition ? 'Add' : 'Edit') +
                          '.label'
                        }
                      />
                    </Button>
                  )}
                </div>
                <div className="col-7 text-right">
                  <ElementGroup>
                    {/* back button */}
                    {this.props.wizard && this.state.step > 0 && (
                      <span>
                        <Button
                          onClick={() => this.previous()}
                          title={this.props.translate(
                            'volunteerForm.button.previous.title'
                          )}
                          alternate={<FontAwesomeIcon icon="chevron-left" />}
                        >
                          <Translate id="volunteerForm.button.previous.label" />
                        </Button>
                      </span>
                    )}

                    {/* delete button */}
                    {this.props.admin &&
                      !this.state.currentlyEditing &&
                      this.props.onDelete && (
                        <HasPermission permissions="manageVolunteers">
                          <Button
                            onClick={() => {
                              confirmAlert({
                                customUI: ({ onClose }) => {
                                  return (
                                    <ConfirmDialog
                                      prompt={this.props.translate(
                                        'volunteerForm.confirmDelete.prompt'
                                      )}
                                      question={this.props.translate(
                                        'volunteerForm.confirmDelete.question',
                                        {
                                          firstName: this.props.entity
                                            .firstName,
                                          lastName: this.props.entity.lastName
                                        }
                                      )}
                                      noTitle={this.props.translate(
                                        'volunteerForm.confirmDelete.button.no.title'
                                      )}
                                      noLabel={this.props.translate(
                                        'volunteerForm.confirmDelete.button.no.label'
                                      )}
                                      yesTitle={this.props.translate(
                                        'volunteerForm.confirmDelete.button.yes.title'
                                      )}
                                      yesLabel={this.props.translate(
                                        'volunteerForm.confirmDelete.button.yes.label'
                                      )}
                                      onClose={onClose}
                                      onConfirm={() => {
                                        // invoke deletion handler
                                        this.props.onDelete(this.props.entity);
                                      }}
                                    />
                                  );
                                }
                              });
                            }}
                            title={this.props.translate(
                              'volunteerForm.button.delete.title'
                            )}
                            alternate={<FontAwesomeIcon icon="trash-alt" />}
                          >
                            <Translate id="volunteerForm.button.delete.label" />
                          </Button>
                        </HasPermission>
                      )}

                    {/* edit button */}
                    {!this.state.currentlyEditing && (
                      <Button
                        onClick={() => {
                          // toggle the edit flag
                          this.setState({
                            currentlyEditing: this.props.entity.id
                          });

                          // go to the top of the form
                          scrollToAnchor(this.props.form, this.props.inModal);
                        }}
                        title={this.props.translate(
                          'volunteerForm.button.edit.title'
                        )}
                        alternate={<FontAwesomeIcon icon="pencil-alt" />}
                      >
                        <Translate id="volunteerForm.button.edit.label" />
                      </Button>
                    )}

                    {/* next/submit button */}
                    {this.state.currentlyEditing && (
                      <Button
                        submit
                        disabled={
                          !consentGranted ||
                          !interestSelected ||
                          (hasFields &&
                            (!this.state.htmlValid ||
                              (this.props.invalid &&
                                !this.props.submitFailed) ||
                              (!this.props.invalid &&
                                !this.props.submitFailed &&
                                this.props.pristine))) ||
                          this.props.submitting
                        }
                        title={this.props.translate(
                          `volunteerForm.button.` +
                            (this.props.wizard && !lastStep
                              ? 'next'
                              : 'submit') +
                            '.title'
                        )}
                        alternate={
                          <FontAwesomeIcon
                            icon={
                              this.props.wizard && !lastStep
                                ? 'chevron-right'
                                : 'check'
                            }
                          />
                        }
                      >
                        <Translate
                          id={
                            `volunteerForm.button.` +
                            (this.props.wizard && !lastStep
                              ? 'next'
                              : 'submit') +
                            '.label'
                          }
                        />
                      </Button>
                    )}
                  </ElementGroup>
                </div>
              </div>
            </div>
          </HasPermission>
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
VolunteerForm = reduxForm({
  // we need to be able to overwrite "other" fields
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,

  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
      dispatch(stopSubmit(props.form));
    }
  }
})(VolunteerForm);

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // pull initial values from the passed in entity
  initialValues: ownProps.entity
    ? {
        ...clone(ownProps.entity),

        // values that are not true don't come back in the response, so we need to explicitly set those
        isHarambeeReader: ownProps.entity.isHarambeeReader ? true : false,

        // make sure a signature is set, even if null
        childProtectionPolicySignature: ownProps.entity
          .childProtectionPolicySignature
          ? ownProps.entity.childProtectionPolicySignature
          : null,

        // if there is no signature, clear the date
        signedChildProtectionPolicyDate: ownProps.entity
          .childProtectionPolicySignature
          ? ownProps.entity.signedChildProtectionPolicyDate
          : null,

        // format interests in a way that the form understands
        interests: (() => {
          const interests = [];
          if (ownProps.entity.interests) {
            for (let i = 0; i < ownProps.entity.interests.length; i++) {
              interests[ownProps.entity.interests[i].id] = true;
            }
          }
          return interests;
        })(),

        // format site affiliations in a way that the form understands
        siteAffiliations: (() => {
          const siteAffiliations = [];
          if (ownProps.entity.siteAffiliations) {
            for (let i = 0; i < ownProps.entity.siteAffiliations.length; i++) {
              siteAffiliations[ownProps.entity.siteAffiliations[i].id] = true;
            }
          }
          return siteAffiliations;
        })(),

        // initialize "other" field
        ...initializeOtherField(
          'language',
          ownProps.entity,
          i18nPersonal.personalForm.field.language.options
        )
      }
    : { isHarambeeReader: false },

  // addition or update?
  addition: !ownProps.entity || !ownProps.entity.id,

  // interests
  formInterests: formValueSelector(ownProps.form)(state, 'interests'),

  // the signature, encoded
  childProtectionPolicySignature: formValueSelector(ownProps.form)(
    state,
    'childProtectionPolicySignature'
  )
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // reset the form
  resetForm: () => {
    dispatch(reset(ownProps.form));
  },

  // reinitializes the form
  reinitializeForm: volunteer => {
    dispatch(initialize(ownProps.form, volunteer));
  }
});

// turn this into a container component
VolunteerForm = withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VolunteerForm)
);

// set prop types and required-ness
VolunteerForm.propTypes = {
  form: PropTypes.string.isRequired,
  wizard: PropTypes.bool,
  admin: PropTypes.bool,
  entity: PropTypes.object,
  interests: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  volunteerOrganizations: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  signChildProtectionPolicy: PropTypes.func,
  onVolunteerUpdate: PropTypes.func,
  translations: PropTypes.object,
  inModal: PropTypes.string
};

// set default props
VolunteerForm.defaultProps = {
  form: 'volunteerForm',
  wizard: false,
  admin: false
};

export default VolunteerForm;
