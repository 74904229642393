import { addAlert } from 'common/components/Alerts/actions';
import { clearContext } from 'common/entities/Context/actions';
import { navigate } from 'common/components/Navigate/actions';
import { clearSession, getSessionId } from 'common/util/session';
import * as v from 'app/variables';

/**
 * Shows the user a message and sends him to the login page.
 */
export function unauthenticated(message, redirect = null) {
  // using thunk middleware, so we return a function
  return dispatch => {
    dispatch(addAlert('error', message, 10000));

    // log the user out
    dispatch(logout());

    // go back to the login page, optionally with redirect
    dispatch(navigate('login', { redirect: redirect }));
  };
}

/**
 * Logs a user out by clearing the context and the session token.
 */
export function logout(onlyLocal = false) {
  // using thunk middleware, so we return a function
  return dispatch => {
    // if logged in, tell the Hub, ignoring any errors
    return (onlyLocal || !getSessionId()
      ? Promise.resolve()
      : dispatch({
          type: 'LOGOUT',
          payload: {
            request: {
              method: 'post',
              url: `/${v.entityType}/logout`
            }
          }
        }).catch(e => {
          console.error(
            'Error killing Hub session; local session was killed, so ignoring',
            e
          );
        })
    ).finally(() => {
      // always clear the session token
      clearSession();

      // always clear the context
      dispatch(clearContext());
    });
  };
}

/**
 * Gets session time remaining.
 */
export function getSessionTimeRemaining() {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'GET_SESSION_TIME_REMAINING',
      payload: {
        request: {
          method: 'get',
          url: `/sessions/timeRemaining`
        }
      }
    });
  };
}

/**
 * Refreshes the session.
 */
export function refreshSession() {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'REFRESH_SESSION',
      payload: {
        request: {
          method: 'put',
          url: `/sessions/refresh`
        }
      }
    });
  };
}
