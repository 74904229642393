// action types
export const CLEAR = 'breadcrumb/CLEAR';
export const REMOVE_TIP = 'breadcrumb/REMOVE_TIP';
export const REGISTER = 'breadcrumb/REGISTER';

// clears the current trail
export const clear = () => ({
  type: CLEAR
});

// removes the current tip, as long as it's not also the root
export const removeTip = () => ({
  type: REMOVE_TIP
});

// registers a new breadcrumb
export const register = (to, text) => ({
  type: REGISTER,
  to: to,
  text: text
});
