import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import BasePage from 'common/components/Page';
import { removeTip } from 'common/components/Breadcrumbs/actions';
import { addAlert } from 'common/components/Alerts/actions';
import { showBusy, hideBusy } from 'common/components/Busy/actions';
import { storeOnContext } from 'common/entities/Context/actions';
import { navigate } from 'common/components/Navigate/actions';
import { clone } from 'common/util';
import {
  addVolunteer,
  updateVolunteer,
  updateVolunteerCredentials
} from 'common/entities/Volunteer/actions';
import VolunteerForm from 'common/forms/VolunteerForm';
import CredentialsForm from 'common/forms/CredentialsForm';
import i18n from './i18n.json';

/* Volunteer management. */
class Volunteer extends BasePage {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }
  }

  render() {
    // parent
    super.render();

    // is this a new account?
    let newAccount = !this.props.volunteer || !this.props.volunteer.id;

    // render
    return (
      <div>
        {!newAccount && !this.props.admin && (
          <CredentialsForm
            entity={this.props.volunteer}
            onSubmit={values =>
              this.props.updateCredentials(values, this.props.volunteer)
            }
          />
        )}
        <VolunteerForm
          admin={this.props.admin}
          entity={this.props.volunteer}
          interests={this.props.interests}
          sites={this.props.sites}
          volunteerOrganizations={this.props.volunteerOrganizations}
          wizard={!this.props.admin && newAccount}
          onCancel={() => {
            // if we have a callback, call it
            if (this.props.onCancel) {
              this.props.onCancel();
            } else {
              this.props.cancel(
                newAccount ? this.props.translate('volunteer.canceled') : null
              );
            }
          }}
          onSubmit={values =>
            this.props.save(values, this.props.volunteer).then(volunteer => {
              // if we have a callback, invoke it
              if (this.props.onSave) {
                this.props.onSave(volunteer);
              }
            })
          }
          onDelete={this.props.onDelete}
          signChildProtectionPolicy={this.props.signChildProtectionPolicy}
          onVolunteerUpdate={this.props.onVolunteerUpdate}
          translations={this.props.translations}
          inModal={this.props.inModal}
        />
      </div>
    );
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // cancels profile creation
  cancel: (message = null) => {
    // potentially pop an alert
    if (message) {
      dispatch(addAlert('success', message));
    }

    // go home
    dispatch(navigate('home'));
  },

  // updates the user's credentials
  updateCredentials: ({ oldCredentials, newCredentials }, volunteer) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // de-structure the volunteer
    const { id, email } = volunteer;

    // make sure something changed
    if (!newCredentials.password && email === newCredentials.userName) {
      // show a message
      return Promise.resolve(
        dispatch(
          addAlert(
            'success',
            ownProps.translate('volunteer.noCredentialsChanges'),
            3000
          )
        )
      ).finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
    }

    // we need to muck with the object, so clone it
    newCredentials = clone(newCredentials);

    // not changing the password?
    if (!newCredentials.password) {
      newCredentials.password = oldCredentials.password;
    }

    // do the update
    return dispatch(
      updateVolunteerCredentials(
        id,
        email,
        newCredentials.userName,
        oldCredentials.password,
        newCredentials.password
      )
    )
      .then(() => {
        // show a success message
        dispatch(
          addAlert(
            'success',
            ownProps.translate('volunteer.credentialsUpdated'),
            3000
          )
        );

        // if the volunteer's email changed, refresh it on the context
        if (email !== newCredentials.userName) {
          volunteer.email = newCredentials.userName;
          dispatch(storeOnContext('volunteer', volunteer));
        }

        // go to the dashboard
        dispatch(navigate('home'));
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // saves the volunteer
  save: (toSave, original = null) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // create or update?
    const update = original && original.id ? true : false;

    // we need to muck with the object, so clone it
    toSave = clone(toSave);

    // stamp CPP boolean
    toSave.childProtectionPolicy =
      toSave.childProtectionPolicy || toSave.childProtectionPolicySignature
        ? true
        : false;

    // if update, cannot change a few things
    if (update) {
      toSave.id = original.id;
    } else {
      // if creation via admin, we invent bogus credentials;
      // the user will just have to reset them
      if (ownProps.admin) {
        if (toSave.email) {
          toSave.credentials = {
            userName: toSave.email,
            password: Math.floor(
              (1 + Math.random()) * 0x1000000000000
            ).toString(16)
          };
        }
      }
    }

    // handle custom language
    if (toSave.language === 'other') {
      toSave.language = toSave.otherLanguage;
    }
    delete toSave.otherLanguage;

    // if we have an address, we need to set the country
    if (toSave.address && toSave.address.street1) {
      toSave.address.country = 'US';
    } else {
      // otherwise, remove the whole address
      delete toSave.address;
    }

    // properly format interests
    if (toSave.interests) {
      const normalized = [];
      for (let j in toSave.interests) {
        if (toSave.interests.hasOwnProperty(j)) {
          if (toSave.interests[j]) {
            // the property key is the ID
            normalized.push({ id: Number(j) });
          }
        }
      }
      toSave.interests = normalized;
    }

    // properly format site affiliations
    if (toSave.siteAffiliations) {
      const normalized = [];
      for (let j in toSave.siteAffiliations) {
        if (toSave.siteAffiliations.hasOwnProperty(j)) {
          if (toSave.siteAffiliations[j]) {
            // the property key is the ID
            normalized.push({ id: Number(j) });
          }
        }
      }
      toSave.siteAffiliations = normalized;
    }

    // get correct function
    const saveVolunteer = update
      ? () => dispatch(updateVolunteer(toSave))
      : () => dispatch(addVolunteer(toSave));

    // do the save
    return saveVolunteer()
      .then(volunteer => {
        // show a success message
        dispatch(
          addAlert(
            'success',
            !update
              ? ownProps.translate('volunteer.created')
              : ownProps.translate('volunteer.updated'),
            !update ? 8000 : 3000
          )
        );

        // if an update, refresh it in the context
        if (update) {
          dispatch(storeOnContext('volunteer', volunteer));
        }

        // if this was an addition by admin and not in a modal, replace the breadcrumb tip
        if (!update && ownProps.admin && !ownProps.inModal) {
          dispatch(removeTip());
        }

        // if a user update, go to the dashboard
        if (update && !ownProps.admin) {
          dispatch(navigate('home'));
        } else if (!ownProps.admin) {
          // otherwise, if a user creation, go to the login page
          dispatch(navigate('login'));
        }

        // propagate the result
        return volunteer;
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  }
});

// turn this into a container component
Volunteer = withLocalize(
  connect(
    null,
    mapDispatchToProps
  )(Volunteer)
);

// set prop types and required-ness
Volunteer.propTypes = {
  admin: PropTypes.bool,
  volunteer: PropTypes.object,
  interests: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  volunteerOrganizations: PropTypes.array.isRequired,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  signChildProtectionPolicy: PropTypes.func,
  onVolunteerUpdate: PropTypes.func,
  translations: PropTypes.object,
  inModal: PropTypes.string
};

// set default props
Volunteer.defaultProps = { admin: false };

export default Volunteer;
