/**
 * Fetches the version from the Hub.
 */
export function fetch() {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'FETCH_VERSION',
      payload: {
        request: {
          method: 'get',
          url: `/admin/version`
        }
      }
    });
  };
}
