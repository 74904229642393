import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BasePureComponent } from 'common/components/Base';
import './styles.scss';

/* A loading indicator. */
class Loading extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // don't show immediately
    this.state = {
      ...this.state,
      show: false
    };
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // delay slightly before showing to avoid flashes
    setTimeout(() => {
      this.setState({ show: true });
    }, 250);
  }

  render() {
    // parent
    super.render();

    // render
    return this.state.show ? (
      <div className="container">
        <div className="row">
          <div
            className={
              `col text-center` +
              (this.props.className ? ` ${this.props.className}` : '')
            }
          >
            <div className="loader">
              <div className="loader--dot" />
              <div className="loader--dot" />
              <div className="loader--dot" />
              <div className="loader--dot" />
              <div className="loader--dot" />
              <div className="loader--dot" />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="fsp-loading-placeholder" />
    );
  }
}

// turn this into a container component
Loading = connect()(Loading);

// set prop types and required-ness
Loading.propTypes = { className: PropTypes.string };

// set default props
Loading.defaultProps = {};

export default Loading;
