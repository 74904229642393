// action types
export const CLEAR_ALL = 'context/CLEAR_ALL';
export const CLEAR_ONE = 'context/CLEAR_ONE';
export const STORE = 'context/STORE';

// clears all items in the context
export const clearContext = () => ({
  type: CLEAR_ALL
});

// clears one item in the context
export const clearFromContext = key => ({
  type: CLEAR_ONE,
  key: key
});

// stores an item in the context
export const storeOnContext = (key, data) => ({
  type: STORE,
  key: key,
  data: data
});
