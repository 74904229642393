import * as a from './actions';

// define the default state
const defaultState = {
  // is the indicator showing?
  busy: [],

  // if showing, when did the indicator appear?
  busyStart: null
};

// define the reducer
export const busy = (state = defaultState, action) => {
  switch (action.type) {
    // register the busy indicator for the given ID; only register a start time if one isn't already registered
    case a.SHOW:
      return {
        ...state,
        busy: [...state.busy, action.id],
        busyStart: state.busyStart ? state.busyStart : Date.now()
      };

    // hide the busy indicator for the provided ID or clear all if no ID provided
    case a.HIDE:
      if (!action.id) {
        // no ID; clear all
        return {
          ...state,
          busy: [],
          busyStart: null
        };
      } else {
        if (
          state.busy &&
          state.busy.length === 1 &&
          state.busy[0] === action.id
        ) {
          // we are clearing the only active indicator
          return {
            ...state,
            busy: [],
            busyStart: null
          };
        } else {
          // we are clearing one indicator, but there are others active; don't reset time
          const index = state.busy.indexOf(action.id);
          return {
            ...state,
            busy: [
              ...state.busy.slice(0, index),
              ...state.busy.slice(index + 1)
            ]
          };
        }
      }

    // returns the current state
    default:
      return state;
  }
};
