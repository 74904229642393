import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BasePureComponent } from 'common/components/Base';
import DataGuard from 'common/components/DataGuard';
import Navigate from 'common/components/Navigate';
import ErrorBoundary from 'common/ErrorBoundary';
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './Main';
import { fetchCurrentProgramYear } from 'common/entities/ProgramYear/actions';

/**
 * The main application.
 */
class App extends BasePureComponent {
  render() {
    // parent
    super.render();

    // render
    return (
      <div id="fsp-app" style={{ width: '100%', height: '100vh' }}>
        <nav>
          <Navigate {...this.props} />
          <Header {...this.props} />
        </nav>
        <main id="fsp-content">
          <ErrorBoundary>
            <DataGuard
              data={{
                currentProgramYear: {
                  authenticated: true,
                  required: true,
                  fetcher: fetchCurrentProgramYear
                }
              }}
            >
              <Main {...this.props} />
            </DataGuard>
          </ErrorBoundary>
        </main>
        <footer>
          <Footer {...this.props} />
        </footer>
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = state => ({
  // the logged in volunteer
  volunteer: state.context.volunteer
});

// make router props accessible; this is necessary to
// drive re-renders based on path changes
export default withRouter(connect(mapStateToProps)(App));
