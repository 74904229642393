import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import moment from 'moment/moment';

import BasePage from 'common/components/Page';
import WidgetContainer from 'common/components/WidgetContainer';
import MessageBox from 'common/components/MessageBox';
import i18n from './i18n.json';
import './styles.scss';

/* Next steps widget. */
class Overview extends BasePage {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // load all necessary data
    this.loadData(this.props);
  }

  loadData(props) {
    // run our loaders, only running each if we found data from the previous
    super.loadData([], results => {
      // return the updated state
      return {};
    });
  }

  render() {
    // parent
    super.render();

    // render user greeting
    const getGreeting = () => {
      if (moment().hour() < 12) {
        return this.props.translate('overview.greeting.morning', {
          firstName: this.props.volunteer.firstName
        });
      } else if (moment().hour() < 18) {
        return this.props.translate('overview.greeting.afternoon', {
          firstName: this.props.volunteer.firstName
        });
      } else {
        return this.props.translate('overview.greeting.evening', {
          firstName: this.props.volunteer.firstName
        });
      }
    };

    // render
    return (
      <WidgetContainer showControls={false}>
        {/* greeting */}
        <div className="fsp-section-header text-right">{getGreeting()}</div>

        {/* good? */}
        {super.dataLoadSuccess() && (
          <div className="fsp-instructions">
            <p>
              <Translate id="overview.welcome" />
            </p>

            {/* CPP? */}
            {!this.props.volunteer.childProtectionPolicy && (
              <MessageBox flavor="warning">
                <Translate id="overview.childProtectionPolicy" />
              </MessageBox>
            )}
          </div>
        )}
      </WidgetContainer>
    );
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = dispatch => ({});

// turn this into a container component
Overview = withLocalize(
  connect(
    null,
    mapDispatchToProps
  )(Overview)
);

// set prop types and required-ness
Overview.propTypes = {
  volunteer: PropTypes.object.isRequired
};

// set default props
Overview.defaultProps = {};

export default Overview;
