import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  formValueSelector,
  reset,
  reduxForm,
  clearSubmitErrors,
  stopSubmit
} from 'redux-form';
import { Translate, withLocalize } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BaseEditForm, processErrors } from 'common/components/Form';
import Text from 'common/components/Form/components/Text';
import Hidden from 'common/components/Form/components/Hidden';
import MessageBox from 'common/components/MessageBox';
import Button from 'common/components/Form/components/Button';
import { scrollToAnchor } from 'common/util';
import * as errors from 'common/util/errors';
import {
  verifyPasswordsMatch,
  verifyPasswordFormat,
  checkValidity
} from 'common/components/Form/utility';
import i18n from './i18n.json';

/* Change credentials form. */
class CredentialsForm extends BaseEditForm {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }
  }

  updateCredentials(values) {
    return this.props
      .onSubmit(values)
      .then(() => {
        // no longer editing
        this.setState({ currentlyEditing: null });

        // reset the form
        this.props.resetForm();
      })
      .catch(e => {
        // process the error(s)
        processErrors(
          e,
          this.props.translate,
          this.props.dispatch,
          null,
          (error, dispatch) => {
            // handle errors specific to this form
            switch (error.code) {
              // not authenticated
              case errors.NOT_AUTHENTICATED:
                return {
                  _error: this.props.translate(
                    'credentialsForm.error.badPassword'
                  )
                };

              // field error
              case 2:
                switch (error.field) {
                  case 'userName':
                    // userName already exists
                    return {
                      newCredentials: {
                        userName: this.props.translate(
                          'credentialsForm.error.duplicateUserName'
                        )
                      },
                      fieldError: true
                    };
                  default:
                    break;
                }
                break;
              default:
                break;
            }

            return null;
          }
        );
      })
      .finally(() => {
        // get back to the top
        scrollToAnchor(this.props.form, this.props.inModal);
      });
  }

  // password format validator
  passwordFormat = value => {
    // defer to utility method
    return verifyPasswordFormat(
      this.props.form,
      'newCredentials.password',
      'credentialsForm',
      this.props.translate,
      value
    );
  };

  // password match check
  passwordsMatch = value => {
    // defer to utility method
    return verifyPasswordsMatch(
      this.props.form,
      'newCredentials.confirmPassword',
      'credentialsForm',
      this.props.translate,
      '#newCredentials\\.password',
      value
    );
  };

  render() {
    // parent
    super.render();

    // render
    return (
      <Form
        id={this.props.form}
        className="d-print-none"
        onSubmit={this.props.handleSubmit(values =>
          this.updateCredentials(values)
        )}
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        <div className="card">
          <div className="card-header">
            <Translate id="credentialsForm.header" />
          </div>
          <div className="card-body">
            {/* errors */}
            {this.props.error && (
              <div className="has-error">
                <div className="fsp-form-error">{this.props.error}</div>
              </div>
            )}

            {/* message */}
            <MessageBox flavor="info">
              <Translate
                id={`credentialsForm.instructions${
                  !this.state.currentlyEditing ? '' : 'Change'
                }`}
              />
            </MessageBox>

            {this.state.currentlyEditing && (
              <div>
                <div className="form-row form-group">
                  {/* userName */}
                  {this.props.showUserName ? (
                    <Text
                      colspan={6}
                      label={this.props.translate(
                        'credentialsForm.field.userName.label'
                      )}
                      name="newCredentials.userName"
                      autoComplete="username"
                      placeholder={this.props.translate(
                        'credentialsForm.field.userName.label'
                      )}
                      tooltip={this.props.translate(
                        'credentialsForm.field.userName.title'
                      )}
                      minLength={3}
                      maxLength={128}
                      required
                      disabled={
                        !this.state.currentlyEditing ||
                        this.props.readOnly ||
                        this.props.submitting
                      }
                    />
                  ) : (
                    <Hidden
                      name="newCredentials.userName"
                      disabled={this.props.readOnly || this.props.submitting}
                    />
                  )}

                  {/* current password */}
                  <Text
                    colspan={6}
                    type="password"
                    label={this.props.translate(
                      'credentialsForm.field.oldPassword.label'
                    )}
                    name="oldCredentials.password"
                    autoComplete="current-password"
                    placeholder={this.props.translate(
                      'credentialsForm.field.oldPassword.label'
                    )}
                    tooltip={this.props.translate(
                      'credentialsForm.field.oldPassword.title'
                    )}
                    minLength={8}
                    maxLength={32}
                    required
                    disabled={
                      !this.state.currentlyEditing ||
                      this.props.readOnly ||
                      this.props.submitting
                    }
                    onChange={() => {
                      this.passwordsMatch();
                    }}
                  />
                </div>

                <div className="form-row">
                  <div className="col mt-3">
                    <Translate id="credentialsForm.passwordLead" />
                  </div>
                </div>
                <div className="form-row form-group">
                  {/* new password */}
                  <Text
                    colspan={6}
                    type="password"
                    label={this.props.translate(
                      'credentialsForm.field.password.label'
                    )}
                    name="newCredentials.password"
                    autoComplete="new-password"
                    placeholder={this.props.translate(
                      'credentialsForm.field.password.label'
                    )}
                    tooltip={this.props.translate(
                      'credentialsForm.field.password.title'
                    )}
                    minLength={8}
                    maxLength={32}
                    validate={[this.passwordFormat, this.passwordsMatch]}
                    required={this.props.confirmPassword ? true : false}
                    disabled={
                      !this.state.currentlyEditing ||
                      this.props.readOnly ||
                      this.props.submitting
                    }
                    onChange={() => {
                      this.passwordsMatch();
                    }}
                  />

                  {/* confirmPassword */}
                  <Text
                    colspan={6}
                    type="password"
                    label={this.props.translate(
                      'credentialsForm.field.confirmPassword.label'
                    )}
                    name="newCredentials.confirmPassword"
                    autoComplete="new-password"
                    placeholder={this.props.translate(
                      'credentialsForm.field.confirmPassword.label'
                    )}
                    tooltip={this.props.translate(
                      'credentialsForm.field.confirmPassword.title'
                    )}
                    minLength={8}
                    maxLength={32}
                    validate={this.passwordsMatch}
                    required={this.props.newPassword ? true : false}
                    disabled={
                      !this.state.currentlyEditing ||
                      this.props.readOnly ||
                      this.props.submitting
                    }
                  />
                </div>
              </div>
            )}
          </div>

          <div className="card-footer">
            <div className="form-row">
              <div className="col-5 text-left">
                {/* cancel button */}
                {this.state.currentlyEditing && (
                  <Button
                    onClick={() => {
                      // reset the form and state
                      this.props.resetForm();
                      this.setState({ currentlyEditing: null });

                      // go to the top
                      scrollToAnchor(this.props.form, this.props.inModal);
                    }}
                    title={this.props.translate(
                      'credentialsForm.button.cancel.title'
                    )}
                    alternate={<FontAwesomeIcon icon="times" />}
                  >
                    <Translate id="credentialsForm.button.cancel.label" />
                  </Button>
                )}
              </div>
              <div className="col-7 text-right">
                {/* edit button */}
                {!this.state.currentlyEditing && (
                  <Button
                    onClick={() => {
                      // toggle the edit flag
                      this.setState({ currentlyEditing: this.props.entity.id });

                      // go to the top
                      scrollToAnchor(this.props.form, this.props.inModal);
                    }}
                    title={this.props.translate(
                      'credentialsForm.button.edit.title'
                    )}
                    alternate={<FontAwesomeIcon icon="pencil-alt" />}
                  >
                    <Translate id="credentialsForm.button.edit.label" />
                  </Button>
                )}

                {/* submit button */}
                {this.state.currentlyEditing && (
                  <Button
                    submit
                    disabled={
                      !this.state.htmlValid ||
                      (this.props.invalid && !this.props.submitFailed) ||
                      (!this.props.invalid &&
                        !this.props.submitFailed &&
                        this.props.pristine) ||
                      this.props.submitting
                    }
                    title={this.props.translate(
                      'credentialsForm.button.submit.title'
                    )}
                    alternate={<FontAwesomeIcon icon="check" />}
                  >
                    <Translate id="credentialsForm.button.submit.label" />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
CredentialsForm = reduxForm({
  // we need to be able to reinitialize fields
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,

  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
      dispatch(stopSubmit(props.form));
    }
  }
})(CredentialsForm);

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // pull initial values from the passed in entity
  initialValues: ownProps.entity
    ? {
        newCredentials: {
          userName: ownProps.emailIsUserName
            ? ownProps.entity.email
            : ownProps.entity.userName
        }
      }
    : {},

  // old password
  oldPassword: formValueSelector(ownProps.form)(
    state,
    'oldCredentials.password'
  ),

  // new password
  newPassword: formValueSelector(ownProps.form)(
    state,
    'newCredentials.password'
  ),

  // confirmed password
  confirmPassword: formValueSelector(ownProps.form)(
    state,
    'newCredentials.confirmPassword'
  )
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // reset the form
  resetForm: () => {
    // reset the form
    dispatch(reset(ownProps.form));
  }
});

// turn this into a container component
CredentialsForm = withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(CredentialsForm)
);

// set prop types and required-ness
CredentialsForm.propTypes = {
  form: PropTypes.string.isRequired,
  entity: PropTypes.object,
  emailIsUserName: PropTypes.bool,
  showUserName: PropTypes.bool,
  translations: PropTypes.object,
  inModal: PropTypes.string
};

// set default props
CredentialsForm.defaultProps = {
  form: 'credentialsForm',
  emailIsUserName: true,
  showUserName: false
};

export default CredentialsForm;
