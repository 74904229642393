import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import { BasePureComponent } from 'common/components/Base';
import Checkbox from 'common/components/Form/components/Checkbox';
import Generic from 'common/components/Form/components/Generic';
import i18n from './i18n.json';
import './styles.scss';

/* Interest information. */
class Interests extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }
  }

  render() {
    // parent
    super.render();

    // renders the interests
    const renderInterests = (props, interests) => {
      return interests.map(function(interest, i) {
        return (
          <div key={interest.id} className="fsp-enroll-interest">
            <Checkbox
              unwrapped
              label={interest.name}
              name={`interests.${interest.id}`}
              tooltip={props.translate(
                `interestsForm.field.interests.interest.title`
              )}
              disabled={props.readOnly || props.submitting}
              translate={props.translate}
            />
          </div>
        );
      });
    };

    // render
    return (
      <div>
        <div className="form-row form-group">
          {/* interests */}
          <Generic
            label={this.props.translate('interestsForm.field.interests.label')}
          >
            <div className="row no-gutters p-0">
              <div className="col-md-6 p-0">
                {renderInterests(
                  this.props,
                  this.props.interests.slice(
                    0,
                    Math.ceil(this.props.interests.length / 2)
                  )
                )}
              </div>
              <div className="col-md-6 p-0">
                {renderInterests(
                  this.props,
                  this.props.interests.slice(
                    Math.ceil(this.props.interests.length / 2)
                  )
                )}
              </div>
            </div>
          </Generic>
        </div>
      </div>
    );
  }
}

// turn this into a container component
Interests = withLocalize(connect()(Interests));

// set prop types and required-ness
Interests.propTypes = {
  form: PropTypes.string.isRequired,
  admin: PropTypes.bool,
  interests: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
  submitting: PropTypes.bool,
  isRestricted: PropTypes.bool,
  translations: PropTypes.object
};

// set default props
Interests.defaultProps = {
  admin: false,
  readOnly: false,
  submitting: false,
  isRestricted: false
};

export default Interests;
