import * as a from './actions';
import { Menu as stateModel } from './state';

// define the reducer
export const menu = (state = stateModel, action) => {
  switch (action.type) {
    // open the menu
    case a.OPEN:
      return {
        open: true
      };

    // close the menu
    case a.CLOSE:
      return {
        open: false
      };

    // toggle the menu
    case a.TOGGLE:
      return {
        open: !state.open
      };

    // returns the current state
    default:
      return state;
  }
};
