// action types
export const GO = 'navigate/GO';
export const CLEAR = 'navigate/CLEAR';

// navigate somewhere
export const navigate = (destination, supplemental = null) => ({
  type: GO,
  destination: destination,
  supplemental: supplemental
});

// clear navigation
export const clearNavigation = () => ({
  type: CLEAR
});
