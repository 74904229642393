import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';

import { BasePureComponent } from 'common/components/Base';
import { showBusy, hideBusy } from 'common/components/Busy/actions';
import { addAlert } from 'common/components/Alerts/actions';
import { navigate } from 'common/components/Navigate/actions';
import { logout } from 'common/entities/Security/actions';
import logoSmall from 'common/images/logo-small.png';
import i18n from './i18n.json';
import './styles.scss';

/* The application's header. */
class Header extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // the incredible shrinking header
    const toShrink = [
      'fsp-header',
      'fsp-header-text',
      'fsp-header-icons',
      'fsp-header-logo',
      'fsp-header-icons'
    ];
    $(document).on('scroll', function() {
      for (let i = 0; i < toShrink.length; i++) {
        if ($(document).scrollTop() > 100) {
          $(`.${toShrink[i]}`).addClass(`${toShrink[i]}-small`);
        } else {
          $(`.${toShrink[i]}`).removeClass(`${toShrink[i]}-small`);
        }
      }
    });
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <div>
        <div id="fsp-header" className="fsp-header fsp-background">
          <div className="fsp-header-container d-flex flex-row flex-grow">
            <div
              id="fsp-header-logo"
              className="fsp-header-logo"
              style={{
                backgroundImage: `url(${logoSmall})`
              }}
              title={this.props.translate('header.home.title')}
              onClick={() => {
                // go home
                this.props.home();
              }}
            />
            <div className="flex-grow" />
            {process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'prod' && (
              <div className="fsp-header-text align-middle">
                {process.env.REACT_APP_ENV &&
                  process.env.REACT_APP_ENV === 'local' && (
                    <span title="You are currently testing in your local environment; this badge will not appear in production">
                      Local
                    </span>
                  )}
                {process.env.REACT_APP_ENV &&
                  process.env.REACT_APP_ENV === 'qa' && (
                    <span title="You are currently testing in the QA environment; this badge will not appear in production">
                      QA
                    </span>
                  )}
                {process.env.REACT_APP_ENV &&
                  process.env.REACT_APP_ENV === 'staging' && (
                    <span title="You are currently testing in the staging environment; this badge will not appear in production">
                      Staging
                    </span>
                  )}
              </div>
            )}
            <div className="flex-grow" />
            <div className="fsp-header-icons">
              {/* logout */}
              {this.props.volunteer && (
                <span
                  id="fsp-header-icon-logout"
                  className="fsp-header-icon align-middle"
                >
                  <span
                    className="fsp-header-icon-text fsp-clickable"
                    title={this.props.translate('header.logout.title')}
                    onClick={() => {
                      // logout
                      this.props.logout();
                    }}
                  >
                    <Translate id="header.logout.label" />{' '}
                  </span>
                  <FontAwesomeIcon
                    icon="sign-out-alt"
                    className="fsp-clickable"
                    title={this.props.translate('header.logout.title')}
                    onClick={() => {
                      // logout
                      this.props.logout();
                    }}
                  />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // go home
  home: () => {
    dispatch(navigate('home'));
  },

  // go to the profile
  profile: () => {
    dispatch(navigate('profile'));
  },

  // logout
  logout: () => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // log the user out
    return dispatch(logout()).finally(() => {
      // get back to the login page
      dispatch(navigate('login'));

      // hide the busy indicator
      dispatch(hideBusy(busyId));

      // pop an alert
      dispatch(
        addAlert('success', ownProps.translate('header.loggedOut'), 2000)
      );
    });
  }
});

// turn this into a container component
Header = withLocalize(connect(null, mapDispatchToProps)(Header));

// set prop types and required-ness
Header.propTypes = { volunteer: PropTypes.object };

// set default props
Header.defaultProps = {};

export default Header;
