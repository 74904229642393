// polyfills
import 'core-js';
import 'raf';
import 'url-search-params-polyfill';
import 'function.name';

import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery/dist/jquery.js';

import Index from 'common/index';
import 'app/styles.scss';

// wrap a provider around the application
ReactDOM.render(<Index />, document.getElementById('root'));
