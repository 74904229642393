import * as a from './actions';

// define the default state
const defaultState = [
  // sample; will be removed
  {
    // unique ID of the alert (for dismissal)
    id: null,

    // type of alert: "info", "success", "warning", or "error"
    flavor: null,

    // alert text
    text: null,

    // alert timeout in milliseconds
    timeout: null
  }
];

// define the reducer
export const alerts = (state = defaultState, action) => {
  switch (action.type) {
    // adds an alert to the list...
    case a.ADD:
      // ... but only if it's not already there
      if (state && Array.isArray(state)) {
        for (let i = 0; i < state.length; i++) {
          // remove the sample
          if (state[i] && !state[i].id) {
            state.splice(i, 1);
          } else if (
            state[i] &&
            state[i].text === action.text &&
            state[i].flavor === action.flavor
          ) {
            // identical match; remove the old one so that
            // we get an updated timeout with the new one
            state.splice(i, 1);
            break;
          }
        }
      }

      // add the alert
      state.push({
        id: action.id,
        flavor: action.flavor,
        text: action.text,
        timeout: action.timeout
      });
      return Object.assign([], state);

    // clears an alert from the list
    case a.CLEAR:
      // remove the identified alert
      state = state.filter(alert => alert.id !== action.id);
      return state;

    // returns the current state
    default:
      return state;
  }
};
