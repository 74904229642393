/**
 * Fetches the current program year.
 */
export function fetchCurrentProgramYear() {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'FETCH_CURRENT_PROGRAM_YEAR',
      payload: {
        request: {
          method: 'get',
          url: `/programYears/current`
        }
      }
    });
  };
}

/**
 * Fetches all program years.
 */
export function fetchAllProgramYears() {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'FETCH_PROGRAM_YEARS',
      payload: {
        request: {
          method: 'get',
          url: `/programYears`
        }
      }
    });
  };
}

/**
 * Fetches a program year by ID.
 */
export function fetchProgramYearById(programYearId) {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'FETCH_PROGRAM_YEAR',
      payload: {
        request: {
          method: 'get',
          url: `/programYears/${programYearId}`
        }
      }
    }).catch(e => {
      // there is one error we handle
      if (e && e.code && e.code === 1300) {
        // no program year with the given ID
        return null;
      } else {
        throw e;
      }
    });
  };
}

/**
 * Fetches program year sites available to a guardian for the current program year.
 */
export function fetchCurrentSiteYearsByGuardian(guardianId) {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'FETCH_CURRENT_SITE_YEARS_BY_GUARDIAN',
      payload: {
        request: {
          method: 'get',
          url: `/programYears/current/guardians/${guardianId}/siteYears`
        }
      }
    });
  };
}

/**
 * Fetches all sites available for a given year.
 */
export function fetchSiteYearsByProgramYear(programYearId, filter = true) {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'FETCH_SITE_YEARS_BY_PROGRAM_YEAR',
      payload: {
        request: {
          method: 'get',
          url: `/programYears/${programYearId}/siteYears${
            filter ? '?filter=true' : ''
          }`
        }
      }
    });
  };
}

/**
 * Fetches all holidays for a program year.
 */
export function fetchHolidaysByProgramYear(programYearId) {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'FETCH_HOLIDAYS',
      payload: {
        request: {
          method: 'get',
          url: `/programYears/${programYearId}/holidays`
        }
      }
    });
  };
}

/**
 * Fetches all site staff training days for a program year.
 */
export function fetchSiteStaffTrainingDaysByProgramYear(programYearId) {
  // using thunk middleware, so we return a function
  return dispatch => {
    // make the call
    return dispatch({
      type: 'FETCH_SITE_STAFF_TRAINING_DAYS',
      payload: {
        request: {
          method: 'get',
          url: `/programYears/${programYearId}/siteStaffTrainings`
        }
      }
    });
  };
}
