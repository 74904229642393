import React from 'react';

import { checkValidity } from 'common/components/Form/utility';

/**
 * A base impure component from which all other impure components should extend.
 *
 * Note that React discourages inheritance. We do it purely for logging. There is
 * no functional behavior in this component nor should there ever be.
 */
export class BaseComponent extends React.Component {
  constructor(props) {
    super(props);

    // nothing for now
    this.state = {};

    // for checking if mounted
    this._isMounted = false;
  }

  render() {
    // do nothing for now
    return null;
  }

  componentDidMount() {
    // flag as mounted
    this._isMounted = true;
  }

  shouldComponentUpdate(nextProps, nextState) {
    // the base implementation always returns true, so that's what we'll do
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    // nothing for now
  }

  componentWillUnmount() {
    // flag as not mounted
    this._isMounted = false;
  }

  componentDidCatch(error, info) {
    // nothing for now
  }

  // convenience method to check if mounted
  isMounted() {
    return this._isMounted;
  }

  setState(state, force = false) {
    if (this._isMounted || force) {
      super.setState(state);
    }
  }
}

/**
 * A base pure component from which all other pure components should extend.
 *
 * Note that React discourages inheritance. We do it purely for logging. There is
 * no functional behavior in this component nor should there ever be.
 */
export class BasePureComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    // for tracking HTML 5 validity
    this.state = { htmlValid: false };

    // for checking if mounted
    this._isMounted = false;
  }

  render() {
    // do nothing for now
    return null;
  }

  componentDidMount() {
    // flag as mounted
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    // if this is a form, check HTML5 validity; this check is necessary
    // to handle situations where a form is pre-populated
    checkValidity(this);
  }

  componentWillUnmount() {
    // flag as not mounted
    this._isMounted = false;
  }

  componentDidCatch(error, info) {
    // nothing for now
  }

  // convenience method to check if mounted
  isMounted() {
    return this._isMounted;
  }

  setState(state, force = false) {
    if (this._isMounted || force) {
      super.setState(state);
    }
  }
}
