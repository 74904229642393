import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { Translate, withLocalize } from 'react-localize-redux';

import { BasePureComponent } from 'common/components/Base';
import Text from 'common/components/Form/components/Text';
import {
  verifyPasswordsMatch,
  verifyPasswordFormat
} from 'common/components/Form/utility';
import i18n from './i18n.json';

/* Credentials creation. */
class Credentials extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // apply overrides
    if (props.translations) {
      props.addTranslation(props.translations);
    }
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // the email address is the user name for now; note that we have to use
    // change() instead of initialize() because it's possible that the user
    // has changed the email address after this component was mounted
    this.props.dispatch(
      change(this.props.form, 'credentials.userName', this.props.email)
    );
  }

  // password format validator
  passwordFormat = value => {
    // defer to utility method
    return verifyPasswordFormat(
      this.props.form,
      'credentials.password',
      'credentialsForm',
      this.props.translate,
      value
    );
  };

  // password match check
  passwordsMatch = value => {
    // defer to utility method
    return verifyPasswordsMatch(
      this.props.form,
      'credentials.confirmPassword',
      'credentialsForm',
      this.props.translate,
      '#credentials\\.password',
      value
    );
  };

  render() {
    // parent
    super.render();

    // render
    return (
      <div>
        <div className="form-row">
          <div className="col">
            <Translate id="credentialsForm.lead" />
          </div>
        </div>
        <div className="form-row form-group">
          {/* email */}
          <Text
            colspan={6}
            colbreak="md"
            type="email"
            label={this.props.translate('credentialsForm.field.email.label')}
            name="credentials.userName"
            autoComplete={!this.props.admin ? 'email' : null}
            placeholder={this.props.translate(
              'credentialsForm.field.email.label'
            )}
            tooltip={this.props.translate('credentialsForm.field.email.title')}
            minLength={5}
            maxLength={128}
            required
            disabled
          />
        </div>

        <div className="form-row form-group">
          {/* password */}
          <Text
            colspan={6}
            colbreak="md"
            type="password"
            label={this.props.translate('credentialsForm.field.password.label')}
            name="credentials.password"
            autoComplete={!this.props.admin ? 'new-password' : null}
            placeholder={this.props.translate(
              'credentialsForm.field.password.label'
            )}
            tooltip={this.props.translate(
              'credentialsForm.field.password.title'
            )}
            minLength={8}
            maxLength={32}
            validate={[this.passwordFormat, this.passwordsMatch]}
            required
            disabled={this.props.readOnly || this.props.submitting}
            onChange={() => {
              this.passwordsMatch();
            }}
          />

          {/* confirmPassword */}
          <Text
            colspan={6}
            colbreak="md"
            type="password"
            label={this.props.translate(
              'credentialsForm.field.confirmPassword.label'
            )}
            name="credentials.confirmPassword"
            autoComplete={!this.props.admin ? 'new-password' : null}
            placeholder={this.props.translate(
              'credentialsForm.field.confirmPassword.label'
            )}
            tooltip={this.props.translate(
              'credentialsForm.field.confirmPassword.title'
            )}
            minLength={8}
            maxLength={32}
            validate={this.passwordsMatch}
            required
            disabled={this.props.readOnly || this.props.submitting}
          />
        </div>
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // email address
  email: formValueSelector(ownProps.form)(state, 'email')
});

// turn this into a container component
Credentials = withLocalize(connect(mapStateToProps)(Credentials));

// set prop types and required-ness
Credentials.propTypes = {
  form: PropTypes.string.isRequired,
  admin: PropTypes.bool,
  readOnly: PropTypes.bool,
  submitting: PropTypes.bool,
  translations: PropTypes.object
};

// set default props
Credentials.defaultProps = {
  admin: false,
  readOnly: false,
  submitting: false
};

export default Credentials;
