import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import requiredIf from 'react-required-if';
import { Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BasePureComponent } from 'common/components/Base';
import { register, clear } from './actions';
import * as v from 'app/variables';
import i18n from './i18n.json';
import './styles.scss';

/* Renders the current breadcrumbs. */
export class Breadcrumbs extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);
  }

  render() {
    // parent
    super.render();

    // render breadcrumbs
    const renderCrumbs = crumbs => {
      const all = [];
      if (crumbs && crumbs.length > 0) {
        // the first crumb is always 'home'
        for (let i = 1; i < crumbs.length; i++) {
          // all crumbs save for the tip are links
          if (i < crumbs.length - 1) {
            all.push(
              <li key={i} className="breadcrumb-item">
                <Link to={crumbs[i].to}>{crumbs[i].text}</Link>
              </li>
            );
          } else {
            all.push(
              <li
                key={i}
                className="breadcrumb-item active"
                aria-current="page"
              >
                {crumbs[i].text}
              </li>
            );
          }
        }
      }
      return all;
    };

    // render
    return (
      <nav className="d-print-none" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <FontAwesomeIcon
                icon="home"
                title={this.props.translate('breadcrumbs.home')}
              />
            </Link>
          </li>
          {renderCrumbs(this.props.crumbs)}
        </ol>
      </nav>
    );
  }
}

// turn this into a container component
Breadcrumbs = withLocalize(
  connect(state => ({
    // current crumbs
    crumbs: state.breadcrumbs.crumbs ? state.breadcrumbs.crumbs : [],

    // current tip
    tip: state.breadcrumbs.tip ? state.breadcrumbs.tip : {}
  }))(Breadcrumbs)
);

// set prop types and required-ness
Breadcrumbs.propTypes = { current: PropTypes.string.isRequired };

// set default props
Breadcrumbs.defaultProps = {};

/* Registers a single breadcrumb. */
export class BreadcrumbsItem extends BasePureComponent {
  componentDidMount() {
    // parent
    super.componentDidMount();

    // register the route
    this.registerRoute(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    // parent
    super.componentDidUpdate(prevProps, prevState);

    // in case of update, re-register the route
    this.registerRoute(this.props);
  }

  registerRoute(props) {
    if (props.home) {
      // clear all
      props.dispatch(clear());

      // reset the document title to the default
      document.title = v.defaultTitle;
    } else {
      // register this route
      props.dispatch(register(props.to, props.text));

      // update the document title to match the tip
      document.title = props.text;
    }
  }

  render() {
    // parent
    super.render();

    // we don't actually render anything
    return null;
  }
}

// turn this into a container component
BreadcrumbsItem = connect()(BreadcrumbsItem);

// set prop types and required-ness
BreadcrumbsItem.propTypes = {
  home: PropTypes.bool,
  to: requiredIf(PropTypes.string, props => !props.home),
  text: requiredIf(PropTypes.string, props => !props.home)
};

// set default props
BreadcrumbsItem.defaultProps = { home: false };
