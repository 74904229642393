import React from 'react';
import { connect } from 'react-redux';

import { BasePureComponent } from 'common/components/Base';
import AppNavigate from 'app/components/Navigate';
import { clearNavigation } from './actions';

/* Navigation redirection. */
class Navigate extends BasePureComponent {
  componentDidUpdate(prevProps, prevState) {
    // parent
    super.componentDidUpdate(prevProps, prevState);

    // avoid recursive navigation
    this.props.clear();

    // no state update
    return null;
  }

  render() {
    // parent
    super.render();

    // render
    if (this.props.destination) {
      // callback to application-specific navigation
      return (
        <AppNavigate
          destination={this.props.destination}
          supplemental={this.props.supplemental}
        />
      );
    } else {
      return null;
    }
  }
}

// map state to properties relevant to this component
function mapStateToProps(state, ownProps) {
  return {
    // go anywhere?
    destination: state.navigate ? state.navigate.destination : null,

    // supplemental information
    supplemental: state.navigate ? state.navigate.supplemental : null
  };
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = dispatch => ({
  // clear
  clear: () => {
    dispatch(clearNavigation());
  }
});

// turn this into a container component
Navigate = connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigate);

// set prop types and required-ness
Navigate.propTypes = {};

// set default props
Navigate.defaultProps = {};

export default Navigate;
